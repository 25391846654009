/* tslint:disable */
/* eslint-disable */
/**
 * CodeServer REST API
 * CodeServer REST API v6.0
 *
 * The version of the OpenAPI document: 6.0
 * Contact: codeserver-support@elisa.fi
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attribute,
    AttributeFromJSON,
    AttributeFromJSONTyped,
    AttributeToJSON,
    Status,
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConceptCode
 */
export interface ConceptCode {
    /**
     * Classification identifier.
     * @type {string}
     * @memberof ConceptCode
     */
    readonly classificationId?: string;
    /**
     * Classification name.
     * @type {string}
     * @memberof ConceptCode
     */
    readonly classificationName?: string;
    /**
     * Version identifier.
     * @type {string}
     * @memberof ConceptCode
     */
    readonly versionId?: string;
    /**
     * Version name.
     * @type {string}
     * @memberof ConceptCode
     */
    readonly versionName?: string;
    /**
     * Concept code identifier. Cannot be updated after insert.
     * @type {string}
     * @memberof ConceptCode
     */
    conceptCodeId?: string;
    /**
     * 
     * @type {Status}
     * @memberof ConceptCode
     */
    status?: Status;
    /**
     * Validity beginning date without time part.
     * @type {Date}
     * @memberof ConceptCode
     */
    beginDate?: Date;
    /**
     * Validity expiration date without time part.
     * @type {Date}
     * @memberof ConceptCode
     */
    expirationDate?: Date;
    /**
     * Last modified date and time.
     * @type {Date}
     * @memberof ConceptCode
     */
    readonly lastModifiedDate?: Date;
    /**
     * Name of last modifier.
     * @type {string}
     * @memberof ConceptCode
     */
    readonly lastModifiedBy?: string;
    /**
     * Creation date and time.
     * @type {Date}
     * @memberof ConceptCode
     */
    readonly createDate?: Date;
    /**
     * Concept code attributes
     * @type {Array<Attribute>}
     * @memberof ConceptCode
     */
    attributes?: Array<Attribute>;
}

export function ConceptCodeFromJSON(json: any): ConceptCode {
    return ConceptCodeFromJSONTyped(json, false);
}

export function ConceptCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classificationId': !exists(json, 'classificationId') ? undefined : json['classificationId'],
        'classificationName': !exists(json, 'classificationName') ? undefined : json['classificationName'],
        'versionId': !exists(json, 'versionId') ? undefined : json['versionId'],
        'versionName': !exists(json, 'versionName') ? undefined : json['versionName'],
        'conceptCodeId': !exists(json, 'conceptCodeId') ? undefined : json['conceptCodeId'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'beginDate': !exists(json, 'beginDate') ? undefined : (new Date(json['beginDate'])),
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (new Date(json['lastModifiedDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeFromJSON)),
    };
}

export function ConceptCodeToJSON(value?: ConceptCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conceptCodeId': value.conceptCodeId,
        'status': StatusToJSON(value.status),
        'beginDate': value.beginDate === undefined ? undefined : (value.beginDate.toISOString().substr(0,10)),
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString().substr(0,10)),
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeToJSON)),
    };
}


