/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MetaformStatistics,
    MetaformStatisticsFromJSON,
    MetaformStatisticsToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models';

export interface GetStatisticsRequest {
    metaformId: string;
}

/**
 * 
 */
export class MetaformStatisticsApi extends runtime.BaseAPI {

    /**
     * This method is allowed for Metaform administrators. Gets Statistics about given Metaform.   Statistics are:    - Date Time of latest Reply   - Amount of unprocessed Replies   - Average Replies per month   - Average process delay for Replies 
     * Gets statistics for given Metaform
     */
    async getStatisticsRaw(requestParameters: GetStatisticsRequest): Promise<runtime.ApiResponse<MetaformStatistics>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling getStatistics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/statistics`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformStatisticsFromJSON(jsonValue));
    }

    /**
     * This method is allowed for Metaform administrators. Gets Statistics about given Metaform.   Statistics are:    - Date Time of latest Reply   - Amount of unprocessed Replies   - Average Replies per month   - Average process delay for Replies 
     * Gets statistics for given Metaform
     */
    async getStatistics(requestParameters: GetStatisticsRequest): Promise<MetaformStatistics> {
        const response = await this.getStatisticsRaw(requestParameters);
        return await response.value();
    }

    /**
     * This method is allowed for Metaform administrators. Gets Statistics about given Metaform.   Statistics are:    - Date Time of latest Reply   - Amount of unprocessed Replies   - Average Replies per month   - Average process delay for Replies 
     * Gets statistics for given Metaform
     */
    async getStatisticsWithHeaders(requestParameters: GetStatisticsRequest): Promise<[ MetaformStatistics, Headers ]> {
        const response = await this.getStatisticsRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
