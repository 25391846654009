/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetaformFieldAutocompleteOptions,
    MetaformFieldAutocompleteOptionsFromJSON,
    MetaformFieldAutocompleteOptionsFromJSONTyped,
    MetaformFieldAutocompleteOptionsToJSON,
    MetaformFieldAutocompleteService,
    MetaformFieldAutocompleteServiceFromJSON,
    MetaformFieldAutocompleteServiceFromJSONTyped,
    MetaformFieldAutocompleteServiceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MetaformFieldAutocomplete
 */
export interface MetaformFieldAutocomplete {
    /**
     * 
     * @type {MetaformFieldAutocompleteService}
     * @memberof MetaformFieldAutocomplete
     */
    service: MetaformFieldAutocompleteService;
    /**
     * 
     * @type {MetaformFieldAutocompleteOptions}
     * @memberof MetaformFieldAutocomplete
     */
    options?: MetaformFieldAutocompleteOptions;
}

export function MetaformFieldAutocompleteFromJSON(json: any): MetaformFieldAutocomplete {
    return MetaformFieldAutocompleteFromJSONTyped(json, false);
}

export function MetaformFieldAutocompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldAutocomplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': MetaformFieldAutocompleteServiceFromJSON(json['service']),
        'options': !exists(json, 'options') ? undefined : MetaformFieldAutocompleteOptionsFromJSON(json['options']),
    };
}

export function MetaformFieldAutocompleteToJSON(value?: MetaformFieldAutocomplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': MetaformFieldAutocompleteServiceToJSON(value.service),
        'options': MetaformFieldAutocompleteOptionsToJSON(value.options),
    };
}


