/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionGroups,
    PermissionGroupsFromJSON,
    PermissionGroupsFromJSONTyped,
    PermissionGroupsToJSON,
} from './';

/**
 * Option object for Metaform field
 * @export
 * @interface MetaformFieldOption
 */
export interface MetaformFieldOption {
    /**
     * Option name
     * @type {string}
     * @memberof MetaformFieldOption
     */
    name: string;
    /**
     * Option text
     * @type {string}
     * @memberof MetaformFieldOption
     */
    text: string;
    /**
     * Defines whether option should be checked by default.
     * @type {boolean}
     * @memberof MetaformFieldOption
     */
    checked?: boolean;
    /**
     * Defines whether option should be selected by default.
     * @type {boolean}
     * @memberof MetaformFieldOption
     */
    selected?: boolean;
    /**
     * 
     * @type {PermissionGroups}
     * @memberof MetaformFieldOption
     */
    permissionGroups?: PermissionGroups;
}

export function MetaformFieldOptionFromJSON(json: any): MetaformFieldOption {
    return MetaformFieldOptionFromJSONTyped(json, false);
}

export function MetaformFieldOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'text': json['text'],
        'checked': !exists(json, 'checked') ? undefined : json['checked'],
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'permissionGroups': !exists(json, 'permissionGroups') ? undefined : PermissionGroupsFromJSON(json['permissionGroups']),
    };
}

export function MetaformFieldOptionToJSON(value?: MetaformFieldOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'text': value.text,
        'checked': value.checked,
        'selected': value.selected,
        'permissionGroups': PermissionGroupsToJSON(value.permissionGroups),
    };
}


