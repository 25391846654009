/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    Reply,
    ReplyFromJSON,
    ReplyToJSON,
    ReplyOrderCriteria,
    ReplyOrderCriteriaFromJSON,
    ReplyOrderCriteriaToJSON,
} from '../models';

export interface ExportRequest {
    metaformId: string;
    format: string;
}

export interface CreateReplyRequest {
    reply: Reply;
    metaformId: string;
    updateExisting?: boolean;
    replyMode?: string;
}

export interface DeleteReplyRequest {
    metaformId: string;
    replyId: string;
    ownerKey?: string;
}

export interface FindReplyRequest {
    metaformId: string;
    replyId: string;
    ownerKey?: string;
}

export interface ListRepliesRequest {
    metaformId: string;
    userId?: string;
    createdBefore?: string;
    createdAfter?: string;
    modifiedBefore?: string;
    modifiedAfter?: string;
    includeRevisions?: boolean;
    fields?: Array<string>;
    firstResult?: number;
    maxResults?: number;
    orderBy?: ReplyOrderCriteria;
    latestFirst?: boolean;
}

export interface ReplyExportRequest {
    metaformId: string;
    replyId: string;
    format: string;
}

export interface UpdateReplyRequest {
    reply: Reply;
    metaformId: string;
    replyId: string;
    ownerKey?: string;
}

/**
 * 
 */
export class RepliesApi extends runtime.BaseAPI {

    /**
     * Exports metaform data  Method requires that logged user is either system admin or metaform admin 
     * Exports metaform data
     */
    async _exportRaw(requestParameters: ExportRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling _export.');
        }

        if (requestParameters.format === null || requestParameters.format === undefined) {
            throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling _export.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/export`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports metaform data  Method requires that logged user is either system admin or metaform admin 
     * Exports metaform data
     */
    async _export(requestParameters: ExportRequest): Promise<Blob> {
        const response = await this._exportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Exports metaform data  Method requires that logged user is either system admin or metaform admin 
     * Exports metaform data
     */
    async _exportWithHeaders(requestParameters: ExportRequest): Promise<[ Blob, Headers ]> {
        const response = await this._exportRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Creates new form reply  For public forms that have allowAnonymous flag on, method requires that user is logged in for forms without the allowAnonymous flag, user needs to have \"user\" role in order to post the reply. 
     * create new form reply
     */
    async createReplyRaw(requestParameters: CreateReplyRequest): Promise<runtime.ApiResponse<Reply>> {
        if (requestParameters.reply === null || requestParameters.reply === undefined) {
            throw new runtime.RequiredError('reply','Required parameter requestParameters.reply was null or undefined when calling createReply.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling createReply.');
        }

        const queryParameters: any = {};

        if (requestParameters.updateExisting !== undefined) {
            queryParameters['updateExisting'] = requestParameters.updateExisting;
        }

        if (requestParameters.replyMode !== undefined) {
            queryParameters['replyMode'] = requestParameters.replyMode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/replies`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReplyToJSON(requestParameters.reply),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReplyFromJSON(jsonValue));
    }

    /**
     * Creates new form reply  For public forms that have allowAnonymous flag on, method requires that user is logged in for forms without the allowAnonymous flag, user needs to have \"user\" role in order to post the reply. 
     * create new form reply
     */
    async createReply(requestParameters: CreateReplyRequest): Promise<Reply> {
        const response = await this.createReplyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates new form reply  For public forms that have allowAnonymous flag on, method requires that user is logged in for forms without the allowAnonymous flag, user needs to have \"user\" role in order to post the reply. 
     * create new form reply
     */
    async createReplyWithHeaders(requestParameters: CreateReplyRequest): Promise<[ Reply, Headers ]> {
        const response = await this.createReplyRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes a reply  Method allows system administrators and metaform administrators to delete a reply. Otherwise user must have reply:edit scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Deletes a reply
     */
    async deleteReplyRaw(requestParameters: DeleteReplyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling deleteReply.');
        }

        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId','Required parameter requestParameters.replyId was null or undefined when calling deleteReply.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerKey !== undefined) {
            queryParameters['ownerKey'] = requestParameters.ownerKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/replies/{replyId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a reply  Method allows system administrators and metaform administrators to delete a reply. Otherwise user must have reply:edit scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Deletes a reply
     */
    async deleteReply(requestParameters: DeleteReplyRequest): Promise<void> {
        await this.deleteReplyRaw(requestParameters);
    }

    /**
     * Deletes a reply  Method allows system administrators and metaform administrators to delete a reply. Otherwise user must have reply:edit scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Deletes a reply
     */
    async deleteReplyWithHeaders(requestParameters: DeleteReplyRequest): Promise<Headers> {
        const response = await this.deleteReplyRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds single reply  Method allows system administrators, metaform administrators and metaform managers to find a reply. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Find a single reply
     */
    async findReplyRaw(requestParameters: FindReplyRequest): Promise<runtime.ApiResponse<Reply>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling findReply.');
        }

        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId','Required parameter requestParameters.replyId was null or undefined when calling findReply.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerKey !== undefined) {
            queryParameters['ownerKey'] = requestParameters.ownerKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/replies/{replyId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReplyFromJSON(jsonValue));
    }

    /**
     * Finds single reply  Method allows system administrators, metaform administrators and metaform managers to find a reply. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Find a single reply
     */
    async findReply(requestParameters: FindReplyRequest): Promise<Reply> {
        const response = await this.findReplyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds single reply  Method allows system administrators, metaform administrators and metaform managers to find a reply. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Find a single reply
     */
    async findReplyWithHeaders(requestParameters: FindReplyRequest): Promise<[ Reply, Headers ]> {
        const response = await this.findReplyRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists form replies  Method allows system administrators, metaform administrators and metaform managers to list all replies. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Lists form replies
     */
    async listRepliesRaw(requestParameters: ListRepliesRequest): Promise<runtime.ApiResponse<Array<Reply>>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling listReplies.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.modifiedBefore !== undefined) {
            queryParameters['modifiedBefore'] = requestParameters.modifiedBefore;
        }

        if (requestParameters.modifiedAfter !== undefined) {
            queryParameters['modifiedAfter'] = requestParameters.modifiedAfter;
        }

        if (requestParameters.includeRevisions !== undefined) {
            queryParameters['includeRevisions'] = requestParameters.includeRevisions;
        }

        if (requestParameters.fields) {
            queryParameters['fields'] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.latestFirst !== undefined) {
            queryParameters['latestFirst'] = requestParameters.latestFirst;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/replies`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReplyFromJSON));
    }

    /**
     * Lists form replies  Method allows system administrators, metaform administrators and metaform managers to list all replies. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Lists form replies
     */
    async listReplies(requestParameters: ListRepliesRequest): Promise<Array<Reply>> {
        const response = await this.listRepliesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists form replies  Method allows system administrators, metaform administrators and metaform managers to list all replies. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Lists form replies
     */
    async listRepliesWithHeaders(requestParameters: ListRepliesRequest): Promise<[ Array<Reply>, Headers ]> {
        const response = await this.listRepliesRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Exports reply data  Method allows system administrators, metaform administrators and metaform managers to export reply data. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Exports reply data
     */
    async replyExportRaw(requestParameters: ReplyExportRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling replyExport.');
        }

        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId','Required parameter requestParameters.replyId was null or undefined when calling replyExport.');
        }

        if (requestParameters.format === null || requestParameters.format === undefined) {
            throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling replyExport.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/replies/{replyId}/export`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports reply data  Method allows system administrators, metaform administrators and metaform managers to export reply data. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Exports reply data
     */
    async replyExport(requestParameters: ReplyExportRequest): Promise<Blob> {
        const response = await this.replyExportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Exports reply data  Method allows system administrators, metaform administrators and metaform managers to export reply data. Otherwise user must have reply:view scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Exports reply data
     */
    async replyExportWithHeaders(requestParameters: ReplyExportRequest): Promise<[ Blob, Headers ]> {
        const response = await this.replyExportRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates reply  Method allows system administrators, metaform administrators and metaform managers to update a reply. Otherwise user must have reply:edit scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Updates reply
     */
    async updateReplyRaw(requestParameters: UpdateReplyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reply === null || requestParameters.reply === undefined) {
            throw new runtime.RequiredError('reply','Required parameter requestParameters.reply was null or undefined when calling updateReply.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling updateReply.');
        }

        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId','Required parameter requestParameters.replyId was null or undefined when calling updateReply.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerKey !== undefined) {
            queryParameters['ownerKey'] = requestParameters.ownerKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/replies/{replyId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplyToJSON(requestParameters.reply),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates reply  Method allows system administrators, metaform administrators and metaform managers to update a reply. Otherwise user must have reply:edit scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Updates reply
     */
    async updateReply(requestParameters: UpdateReplyRequest): Promise<void> {
        await this.updateReplyRaw(requestParameters);
    }

    /**
     * Updates reply  Method allows system administrators, metaform administrators and metaform managers to update a reply. Otherwise user must have reply:edit scope permission for given reply. Permissions are handled by the Keycloak authorization system. 
     * Updates reply
     */
    async updateReplyWithHeaders(requestParameters: UpdateReplyRequest): Promise<Headers> {
        const response = await this.updateReplyRaw(requestParameters);
        return response.raw.headers;
    }

}
