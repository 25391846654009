/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MetaformMemberGroup,
    MetaformMemberGroupFromJSON,
    MetaformMemberGroupToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models';

export interface CreateMetaformMemberGroupRequest {
    metaformMemberGroup: MetaformMemberGroup;
    metaformId: string;
}

export interface DeleteMetaformMemberGroupRequest {
    metaformId: string;
    metaformMemberGroupId: string;
}

export interface FindMetaformMemberGroupRequest {
    metaformId: string;
    metaformMemberGroupId: string;
}

export interface ListMetaformMemberGroupsRequest {
    metaformId: string;
}

export interface UpdateMetaformMemberGroupRequest {
    metaformMemberGroup: MetaformMemberGroup;
    metaformId: string;
    metaformMemberGroupId: string;
}

/**
 * 
 */
export class MetaformMemberGroupsApi extends runtime.BaseAPI {

    /**
     * Creates metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Create new metaform member group
     */
    async createMetaformMemberGroupRaw(requestParameters: CreateMetaformMemberGroupRequest): Promise<runtime.ApiResponse<MetaformMemberGroup>> {
        if (requestParameters.metaformMemberGroup === null || requestParameters.metaformMemberGroup === undefined) {
            throw new runtime.RequiredError('metaformMemberGroup','Required parameter requestParameters.metaformMemberGroup was null or undefined when calling createMetaformMemberGroup.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling createMetaformMemberGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/memberGroups`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformMemberGroupToJSON(requestParameters.metaformMemberGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformMemberGroupFromJSON(jsonValue));
    }

    /**
     * Creates metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Create new metaform member group
     */
    async createMetaformMemberGroup(requestParameters: CreateMetaformMemberGroupRequest): Promise<MetaformMemberGroup> {
        const response = await this.createMetaformMemberGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Create new metaform member group
     */
    async createMetaformMemberGroupWithHeaders(requestParameters: CreateMetaformMemberGroupRequest): Promise<[ MetaformMemberGroup, Headers ]> {
        const response = await this.createMetaformMemberGroupRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Deletes metaform manager group
     */
    async deleteMetaformMemberGroupRaw(requestParameters: DeleteMetaformMemberGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling deleteMetaformMemberGroup.');
        }

        if (requestParameters.metaformMemberGroupId === null || requestParameters.metaformMemberGroupId === undefined) {
            throw new runtime.RequiredError('metaformMemberGroupId','Required parameter requestParameters.metaformMemberGroupId was null or undefined when calling deleteMetaformMemberGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/memberGroups/{metaformMemberGroupId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"metaformMemberGroupId"}}`, encodeURIComponent(String(requestParameters.metaformMemberGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Deletes metaform manager group
     */
    async deleteMetaformMemberGroup(requestParameters: DeleteMetaformMemberGroupRequest): Promise<void> {
        await this.deleteMetaformMemberGroupRaw(requestParameters);
    }

    /**
     * Deletes metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Deletes metaform manager group
     */
    async deleteMetaformMemberGroupWithHeaders(requestParameters: DeleteMetaformMemberGroupRequest): Promise<Headers> {
        const response = await this.deleteMetaformMemberGroupRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Find metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Finds single metaform member group
     */
    async findMetaformMemberGroupRaw(requestParameters: FindMetaformMemberGroupRequest): Promise<runtime.ApiResponse<MetaformMemberGroup>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling findMetaformMemberGroup.');
        }

        if (requestParameters.metaformMemberGroupId === null || requestParameters.metaformMemberGroupId === undefined) {
            throw new runtime.RequiredError('metaformMemberGroupId','Required parameter requestParameters.metaformMemberGroupId was null or undefined when calling findMetaformMemberGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/memberGroups/{metaformMemberGroupId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"metaformMemberGroupId"}}`, encodeURIComponent(String(requestParameters.metaformMemberGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformMemberGroupFromJSON(jsonValue));
    }

    /**
     * Find metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Finds single metaform member group
     */
    async findMetaformMemberGroup(requestParameters: FindMetaformMemberGroupRequest): Promise<MetaformMemberGroup> {
        const response = await this.findMetaformMemberGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Find metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Finds single metaform member group
     */
    async findMetaformMemberGroupWithHeaders(requestParameters: FindMetaformMemberGroupRequest): Promise<[ MetaformMemberGroup, Headers ]> {
        const response = await this.findMetaformMemberGroupRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists all metaform member groups  Method is allowed only for system administrators and metaform administrators 
     * list all metaform member groups
     */
    async listMetaformMemberGroupsRaw(requestParameters: ListMetaformMemberGroupsRequest): Promise<runtime.ApiResponse<Array<MetaformMemberGroup>>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling listMetaformMemberGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/memberGroups`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetaformMemberGroupFromJSON));
    }

    /**
     * Lists all metaform member groups  Method is allowed only for system administrators and metaform administrators 
     * list all metaform member groups
     */
    async listMetaformMemberGroups(requestParameters: ListMetaformMemberGroupsRequest): Promise<Array<MetaformMemberGroup>> {
        const response = await this.listMetaformMemberGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all metaform member groups  Method is allowed only for system administrators and metaform administrators 
     * list all metaform member groups
     */
    async listMetaformMemberGroupsWithHeaders(requestParameters: ListMetaformMemberGroupsRequest): Promise<[ Array<MetaformMemberGroup>, Headers ]> {
        const response = await this.listMetaformMemberGroupsRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Updates metaform member group
     */
    async updateMetaformMemberGroupRaw(requestParameters: UpdateMetaformMemberGroupRequest): Promise<runtime.ApiResponse<MetaformMemberGroup>> {
        if (requestParameters.metaformMemberGroup === null || requestParameters.metaformMemberGroup === undefined) {
            throw new runtime.RequiredError('metaformMemberGroup','Required parameter requestParameters.metaformMemberGroup was null or undefined when calling updateMetaformMemberGroup.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling updateMetaformMemberGroup.');
        }

        if (requestParameters.metaformMemberGroupId === null || requestParameters.metaformMemberGroupId === undefined) {
            throw new runtime.RequiredError('metaformMemberGroupId','Required parameter requestParameters.metaformMemberGroupId was null or undefined when calling updateMetaformMemberGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/memberGroups/{metaformMemberGroupId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"metaformMemberGroupId"}}`, encodeURIComponent(String(requestParameters.metaformMemberGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformMemberGroupToJSON(requestParameters.metaformMemberGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformMemberGroupFromJSON(jsonValue));
    }

    /**
     * Updates metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Updates metaform member group
     */
    async updateMetaformMemberGroup(requestParameters: UpdateMetaformMemberGroupRequest): Promise<MetaformMemberGroup> {
        const response = await this.updateMetaformMemberGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates metaform member group  Method is allowed only for system administrators and metaform administrators 
     * Updates metaform member group
     */
    async updateMetaformMemberGroupWithHeaders(requestParameters: UpdateMetaformMemberGroupRequest): Promise<[ MetaformMemberGroup, Headers ]> {
        const response = await this.updateMetaformMemberGroupRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
