/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines member groups for permissions. Object can be used either as default permission groups for single form or 
 * in a field option level. 
 * 
 * More detailed descriptions of behaviours in Metaform and MetaformFieldOption objects.
 * @export
 * @interface PermissionGroups
 */
export interface PermissionGroups {
    /**
     * Member group ids of groups that will receive reply:view permission to the posted reply.
     * @type {Array<string>}
     * @memberof PermissionGroups
     */
    viewGroupIds?: Array<string>;
    /**
     * Member group ids of groups that will receive reply:edit permission to the posted reply.
     * @type {Array<string>}
     * @memberof PermissionGroups
     */
    editGroupIds?: Array<string>;
    /**
     * Member group ids of groups that will receive reply:notify permission to the posted reply.
     * @type {Array<string>}
     * @memberof PermissionGroups
     */
    notifyGroupIds?: Array<string>;
}

export function PermissionGroupsFromJSON(json: any): PermissionGroups {
    return PermissionGroupsFromJSONTyped(json, false);
}

export function PermissionGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewGroupIds': !exists(json, 'viewGroupIds') ? undefined : json['viewGroupIds'],
        'editGroupIds': !exists(json, 'editGroupIds') ? undefined : json['editGroupIds'],
        'notifyGroupIds': !exists(json, 'notifyGroupIds') ? undefined : json['notifyGroupIds'],
    };
}

export function PermissionGroupsToJSON(value?: PermissionGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewGroupIds': value.viewGroupIds,
        'editGroupIds': value.editGroupIds,
        'notifyGroupIds': value.notifyGroupIds,
    };
}


