/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaformFieldSchedule
 */
export interface MetaformFieldSchedule {
    /**
     * 
     * @type {Date}
     * @memberof MetaformFieldSchedule
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MetaformFieldSchedule
     */
    endTime?: Date;
}

export function MetaformFieldScheduleFromJSON(json: any): MetaformFieldSchedule {
    return MetaformFieldScheduleFromJSONTyped(json, false);
}

export function MetaformFieldScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
    };
}

export function MetaformFieldScheduleToJSON(value?: MetaformFieldSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
    };
}


