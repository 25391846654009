/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetaformMemberRole,
    MetaformMemberRoleFromJSON,
    MetaformMemberRoleFromJSONTyped,
    MetaformMemberRoleToJSON,
} from './';

/**
 * Form member or manager user.
 * @export
 * @interface MetaformMember
 */
export interface MetaformMember {
    /**
     * Id of the form member or manager user in Keycloak.
     * @type {string}
     * @memberof MetaformMember
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaformMember
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MetaformMember
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof MetaformMember
     */
    lastName: string;
    /**
     * 
     * @type {MetaformMemberRole}
     * @memberof MetaformMember
     */
    role: MetaformMemberRole;
}

export function MetaformMemberFromJSON(json: any): MetaformMember {
    return MetaformMemberFromJSONTyped(json, false);
}

export function MetaformMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'role': MetaformMemberRoleFromJSON(json['role']),
    };
}

export function MetaformMemberToJSON(value?: MetaformMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'role': MetaformMemberRoleToJSON(value.role),
    };
}


