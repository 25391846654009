/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ScriptType {
    AfterCreateReply = 'AFTER_CREATE_REPLY',
    AfterUpdateReply = 'AFTER_UPDATE_REPLY',
    ExportXlsx = 'EXPORT_XLSX'
}

export function ScriptTypeFromJSON(json: any): ScriptType {
    return ScriptTypeFromJSONTyped(json, false);
}

export function ScriptTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScriptType {
    return json as ScriptType;
}

export function ScriptTypeToJSON(value?: ScriptType | null): any {
    return value as any;
}

