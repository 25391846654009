/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MetaformMember,
    MetaformMemberFromJSON,
    MetaformMemberToJSON,
    MetaformMemberRole,
    MetaformMemberRoleFromJSON,
    MetaformMemberRoleToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models';

export interface CreateMetaformMemberRequest {
    metaformMember: MetaformMember;
    metaformId: string;
}

export interface DeleteMetaformMemberRequest {
    metaformId: string;
    metaformMemberId: string;
}

export interface FindMetaformMemberRequest {
    metaformId: string;
    metaformMemberId: string;
}

export interface ListMetaformMembersRequest {
    metaformId: string;
    role?: MetaformMemberRole;
}

export interface UpdateMetaformMemberRequest {
    metaformMember: MetaformMember;
    metaformId: string;
    metaformMemberId: string;
}

/**
 * 
 */
export class MetaformMembersApi extends runtime.BaseAPI {

    /**
     * Creates new metaform member  Method is allowed only for system administrators and metaform administrators 
     * create new metaform member
     */
    async createMetaformMemberRaw(requestParameters: CreateMetaformMemberRequest): Promise<runtime.ApiResponse<MetaformMember>> {
        if (requestParameters.metaformMember === null || requestParameters.metaformMember === undefined) {
            throw new runtime.RequiredError('metaformMember','Required parameter requestParameters.metaformMember was null or undefined when calling createMetaformMember.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling createMetaformMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/members`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformMemberToJSON(requestParameters.metaformMember),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformMemberFromJSON(jsonValue));
    }

    /**
     * Creates new metaform member  Method is allowed only for system administrators and metaform administrators 
     * create new metaform member
     */
    async createMetaformMember(requestParameters: CreateMetaformMemberRequest): Promise<MetaformMember> {
        const response = await this.createMetaformMemberRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates new metaform member  Method is allowed only for system administrators and metaform administrators 
     * create new metaform member
     */
    async createMetaformMemberWithHeaders(requestParameters: CreateMetaformMemberRequest): Promise<[ MetaformMember, Headers ]> {
        const response = await this.createMetaformMemberRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes metaform member  Method is allowed only for system administrators and metaform administrators 
     * Deletes metaform member
     */
    async deleteMetaformMemberRaw(requestParameters: DeleteMetaformMemberRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling deleteMetaformMember.');
        }

        if (requestParameters.metaformMemberId === null || requestParameters.metaformMemberId === undefined) {
            throw new runtime.RequiredError('metaformMemberId','Required parameter requestParameters.metaformMemberId was null or undefined when calling deleteMetaformMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/members/{metaformMemberId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"metaformMemberId"}}`, encodeURIComponent(String(requestParameters.metaformMemberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes metaform member  Method is allowed only for system administrators and metaform administrators 
     * Deletes metaform member
     */
    async deleteMetaformMember(requestParameters: DeleteMetaformMemberRequest): Promise<void> {
        await this.deleteMetaformMemberRaw(requestParameters);
    }

    /**
     * Deletes metaform member  Method is allowed only for system administrators and metaform administrators 
     * Deletes metaform member
     */
    async deleteMetaformMemberWithHeaders(requestParameters: DeleteMetaformMemberRequest): Promise<Headers> {
        const response = await this.deleteMetaformMemberRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds metaform member  Method is allowed only for system administrators and metaform administrators 
     * Finds a metaform member
     */
    async findMetaformMemberRaw(requestParameters: FindMetaformMemberRequest): Promise<runtime.ApiResponse<MetaformMember>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling findMetaformMember.');
        }

        if (requestParameters.metaformMemberId === null || requestParameters.metaformMemberId === undefined) {
            throw new runtime.RequiredError('metaformMemberId','Required parameter requestParameters.metaformMemberId was null or undefined when calling findMetaformMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/members/{metaformMemberId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"metaformMemberId"}}`, encodeURIComponent(String(requestParameters.metaformMemberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformMemberFromJSON(jsonValue));
    }

    /**
     * Finds metaform member  Method is allowed only for system administrators and metaform administrators 
     * Finds a metaform member
     */
    async findMetaformMember(requestParameters: FindMetaformMemberRequest): Promise<MetaformMember> {
        const response = await this.findMetaformMemberRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds metaform member  Method is allowed only for system administrators and metaform administrators 
     * Finds a metaform member
     */
    async findMetaformMemberWithHeaders(requestParameters: FindMetaformMemberRequest): Promise<[ MetaformMember, Headers ]> {
        const response = await this.findMetaformMemberRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists all metaform members  Method is allowed only for system administrators and metaform administrators 
     * list all metaform members
     */
    async listMetaformMembersRaw(requestParameters: ListMetaformMembersRequest): Promise<runtime.ApiResponse<Array<MetaformMember>>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling listMetaformMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/members`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetaformMemberFromJSON));
    }

    /**
     * Lists all metaform members  Method is allowed only for system administrators and metaform administrators 
     * list all metaform members
     */
    async listMetaformMembers(requestParameters: ListMetaformMembersRequest): Promise<Array<MetaformMember>> {
        const response = await this.listMetaformMembersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all metaform members  Method is allowed only for system administrators and metaform administrators 
     * list all metaform members
     */
    async listMetaformMembersWithHeaders(requestParameters: ListMetaformMembersRequest): Promise<[ Array<MetaformMember>, Headers ]> {
        const response = await this.listMetaformMembersRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates metaform member  Method is allowed only for system administrators and metaform administrators 
     * Updates metaform member
     */
    async updateMetaformMemberRaw(requestParameters: UpdateMetaformMemberRequest): Promise<runtime.ApiResponse<MetaformMember>> {
        if (requestParameters.metaformMember === null || requestParameters.metaformMember === undefined) {
            throw new runtime.RequiredError('metaformMember','Required parameter requestParameters.metaformMember was null or undefined when calling updateMetaformMember.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling updateMetaformMember.');
        }

        if (requestParameters.metaformMemberId === null || requestParameters.metaformMemberId === undefined) {
            throw new runtime.RequiredError('metaformMemberId','Required parameter requestParameters.metaformMemberId was null or undefined when calling updateMetaformMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/members/{metaformMemberId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"metaformMemberId"}}`, encodeURIComponent(String(requestParameters.metaformMemberId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformMemberToJSON(requestParameters.metaformMember),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformMemberFromJSON(jsonValue));
    }

    /**
     * Updates metaform member  Method is allowed only for system administrators and metaform administrators 
     * Updates metaform member
     */
    async updateMetaformMember(requestParameters: UpdateMetaformMemberRequest): Promise<MetaformMember> {
        const response = await this.updateMetaformMemberRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates metaform member  Method is allowed only for system administrators and metaform administrators 
     * Updates metaform member
     */
    async updateMetaformMemberWithHeaders(requestParameters: UpdateMetaformMemberRequest): Promise<[ MetaformMember, Headers ]> {
        const response = await this.updateMetaformMemberRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
