/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
} from '../models';

export interface FindAttachmentRequest {
    attachmentId: string;
    ownerKey?: string;
}

export interface FindAttachmentDataRequest {
    attachmentId: string;
    ownerKey?: string;
}

/**
 * 
 */
export class AttachmentsApi extends runtime.BaseAPI {

    /**
     * Finds an attachment  Method allows system administrators, metaform administrators and metaform managers to find a attachment.  Otherwise user must have reply:view scope permission for the reply associated with the attachment. Permissions are handled by the Keycloak authorization system. 
     * Find an attachment by id
     */
    async findAttachmentRaw(requestParameters: FindAttachmentRequest): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling findAttachment.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerKey !== undefined) {
            queryParameters['ownerKey'] = requestParameters.ownerKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/attachments/{attachmentId}`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Finds an attachment  Method allows system administrators, metaform administrators and metaform managers to find a attachment.  Otherwise user must have reply:view scope permission for the reply associated with the attachment. Permissions are handled by the Keycloak authorization system. 
     * Find an attachment by id
     */
    async findAttachment(requestParameters: FindAttachmentRequest): Promise<Attachment> {
        const response = await this.findAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds an attachment  Method allows system administrators, metaform administrators and metaform managers to find a attachment.  Otherwise user must have reply:view scope permission for the reply associated with the attachment. Permissions are handled by the Keycloak authorization system. 
     * Find an attachment by id
     */
    async findAttachmentWithHeaders(requestParameters: FindAttachmentRequest): Promise<[ Attachment, Headers ]> {
        const response = await this.findAttachmentRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Finds an attachment data  Method allows system administrators, metaform administrators and metaform managers to find a attachment data.  Otherwise user must have reply:view scope permission for the reply associated with the attachment. Permissions are handled by the Keycloak authorization system. 
     * Find a attachment data by id
     */
    async findAttachmentDataRaw(requestParameters: FindAttachmentDataRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling findAttachmentData.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerKey !== undefined) {
            queryParameters['ownerKey'] = requestParameters.ownerKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/attachments/{attachmentId}/data`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Finds an attachment data  Method allows system administrators, metaform administrators and metaform managers to find a attachment data.  Otherwise user must have reply:view scope permission for the reply associated with the attachment. Permissions are handled by the Keycloak authorization system. 
     * Find a attachment data by id
     */
    async findAttachmentData(requestParameters: FindAttachmentDataRequest): Promise<Blob> {
        const response = await this.findAttachmentDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds an attachment data  Method allows system administrators, metaform administrators and metaform managers to find a attachment data.  Otherwise user must have reply:view scope permission for the reply associated with the attachment. Permissions are handled by the Keycloak authorization system. 
     * Find a attachment data by id
     */
    async findAttachmentDataWithHeaders(requestParameters: FindAttachmentDataRequest): Promise<[ Blob, Headers ]> {
        const response = await this.findAttachmentDataRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
