/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaformFieldFlags
 */
export interface MetaformFieldFlags {
    /**
     * Field should be editable in management service
     * @type {boolean}
     * @memberof MetaformFieldFlags
     */
    managementEditable?: boolean;
}

export function MetaformFieldFlagsFromJSON(json: any): MetaformFieldFlags {
    return MetaformFieldFlagsFromJSONTyped(json, false);
}

export function MetaformFieldFlagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldFlags {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managementEditable': !exists(json, 'managementEditable') ? undefined : json['managementEditable'],
    };
}

export function MetaformFieldFlagsToJSON(value?: MetaformFieldFlags | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managementEditable': value.managementEditable,
    };
}


