/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Conflict,
    ConflictFromJSON,
    ConflictToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Metaform,
    MetaformFromJSON,
    MetaformToJSON,
    MetaformMemberRole,
    MetaformMemberRoleFromJSON,
    MetaformMemberRoleToJSON,
    MetaformVisibility,
    MetaformVisibilityFromJSON,
    MetaformVisibilityToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models';

export interface CreateMetaformRequest {
    metaform: Metaform;
}

export interface DeleteMetaformRequest {
    metaformId: string;
}

export interface FindMetaformRequest {
    metaformSlug?: string;
    metaformId?: string;
    replyId?: string;
    ownerKey?: string;
}

export interface ListMetaformsRequest {
    visibility?: MetaformVisibility;
    memberRole?: MetaformMemberRole;
}

export interface UpdateMetaformRequest {
    metaform: Metaform;
    metaformId: string;
}

/**
 * 
 */
export class MetaformsApi extends runtime.BaseAPI {

    /**
     * Creates new Metaform  Method is allowed for system administrators 
     * create new Metaform
     */
    async createMetaformRaw(requestParameters: CreateMetaformRequest): Promise<runtime.ApiResponse<Metaform>> {
        if (requestParameters.metaform === null || requestParameters.metaform === undefined) {
            throw new runtime.RequiredError('metaform','Required parameter requestParameters.metaform was null or undefined when calling createMetaform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformToJSON(requestParameters.metaform),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformFromJSON(jsonValue));
    }

    /**
     * Creates new Metaform  Method is allowed for system administrators 
     * create new Metaform
     */
    async createMetaform(requestParameters: CreateMetaformRequest): Promise<Metaform> {
        const response = await this.createMetaformRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates new Metaform  Method is allowed for system administrators 
     * create new Metaform
     */
    async createMetaformWithHeaders(requestParameters: CreateMetaformRequest): Promise<[ Metaform, Headers ]> {
        const response = await this.createMetaformRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes Metaform  Method is allowed for system administrators and metaform administrators 
     * Deletes Metaform
     */
    async deleteMetaformRaw(requestParameters: DeleteMetaformRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling deleteMetaform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes Metaform  Method is allowed for system administrators and metaform administrators 
     * Deletes Metaform
     */
    async deleteMetaform(requestParameters: DeleteMetaformRequest): Promise<void> {
        await this.deleteMetaformRaw(requestParameters);
    }

    /**
     * Deletes Metaform  Method is allowed for system administrators and metaform administrators 
     * Deletes Metaform
     */
    async deleteMetaformWithHeaders(requestParameters: DeleteMetaformRequest): Promise<Headers> {
        const response = await this.deleteMetaformRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds a Metaform by slug or id  Method allows public metaforms for all users but private metaforms require that logged user is either system admin, metaform admin or metaform manager 
     * Finds single Metaform
     */
    async findMetaformRaw(requestParameters: FindMetaformRequest): Promise<runtime.ApiResponse<Metaform>> {
        const queryParameters: any = {};

        if (requestParameters.metaformSlug !== undefined) {
            queryParameters['metaformSlug'] = requestParameters.metaformSlug;
        }

        if (requestParameters.metaformId !== undefined) {
            queryParameters['metaformId'] = requestParameters.metaformId;
        }

        if (requestParameters.replyId !== undefined) {
            queryParameters['replyId'] = requestParameters.replyId;
        }

        if (requestParameters.ownerKey !== undefined) {
            queryParameters['ownerKey'] = requestParameters.ownerKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaform`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformFromJSON(jsonValue));
    }

    /**
     * Finds a Metaform by slug or id  Method allows public metaforms for all users but private metaforms require that logged user is either system admin, metaform admin or metaform manager 
     * Finds single Metaform
     */
    async findMetaform(requestParameters: FindMetaformRequest = {}): Promise<Metaform> {
        const response = await this.findMetaformRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds a Metaform by slug or id  Method allows public metaforms for all users but private metaforms require that logged user is either system admin, metaform admin or metaform manager 
     * Finds single Metaform
     */
    async findMetaformWithHeaders(requestParameters: FindMetaformRequest): Promise<[ Metaform, Headers ]> {
        const response = await this.findMetaformRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists Metaforms.  Method allows public metaforms for all users but private metaforms require that user is logged in 
     * Lists Metaforms
     */
    async listMetaformsRaw(requestParameters: ListMetaformsRequest): Promise<runtime.ApiResponse<Array<Metaform>>> {
        const queryParameters: any = {};

        if (requestParameters.visibility !== undefined) {
            queryParameters['visibility'] = requestParameters.visibility;
        }

        if (requestParameters.memberRole !== undefined) {
            queryParameters['memberRole'] = requestParameters.memberRole;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetaformFromJSON));
    }

    /**
     * Lists Metaforms.  Method allows public metaforms for all users but private metaforms require that user is logged in 
     * Lists Metaforms
     */
    async listMetaforms(requestParameters: ListMetaformsRequest = {}): Promise<Array<Metaform>> {
        const response = await this.listMetaformsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists Metaforms.  Method allows public metaforms for all users but private metaforms require that user is logged in 
     * Lists Metaforms
     */
    async listMetaformsWithHeaders(requestParameters: ListMetaformsRequest): Promise<[ Array<Metaform>, Headers ]> {
        const response = await this.listMetaformsRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates Metaform  Method is allowed for system administrators and metaform administrators 
     * Updates Metaform
     */
    async updateMetaformRaw(requestParameters: UpdateMetaformRequest): Promise<runtime.ApiResponse<Metaform>> {
        if (requestParameters.metaform === null || requestParameters.metaform === undefined) {
            throw new runtime.RequiredError('metaform','Required parameter requestParameters.metaform was null or undefined when calling updateMetaform.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling updateMetaform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformToJSON(requestParameters.metaform),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformFromJSON(jsonValue));
    }

    /**
     * Updates Metaform  Method is allowed for system administrators and metaform administrators 
     * Updates Metaform
     */
    async updateMetaform(requestParameters: UpdateMetaformRequest): Promise<Metaform> {
        const response = await this.updateMetaformRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates Metaform  Method is allowed for system administrators and metaform administrators 
     * Updates Metaform
     */
    async updateMetaformWithHeaders(requestParameters: UpdateMetaformRequest): Promise<[ Metaform, Headers ]> {
        const response = await this.updateMetaformRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
