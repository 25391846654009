/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TemplateData,
    TemplateDataFromJSON,
    TemplateDataFromJSONTyped,
    TemplateDataToJSON,
    TemplateVisibility,
    TemplateVisibilityFromJSON,
    TemplateVisibilityFromJSONTyped,
    TemplateVisibilityToJSON,
} from './';

/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    id?: string;
    /**
     * 
     * @type {TemplateData}
     * @memberof Template
     */
    data: TemplateData;
    /**
     * 
     * @type {TemplateVisibility}
     * @memberof Template
     */
    visibility: TemplateVisibility;
    /**
     * Created date
     * @type {Date}
     * @memberof Template
     */
    readonly createdAt?: Date;
    /**
     * Modified data
     * @type {Date}
     * @memberof Template
     */
    readonly modifiedAt?: Date;
    /**
     * ID of creator
     * @type {string}
     * @memberof Template
     */
    readonly creatorId?: string;
    /**
     * ID of last modifier
     * @type {string}
     * @memberof Template
     */
    readonly lastModifierId?: string;
}

export function TemplateFromJSON(json: any): Template {
    return TemplateFromJSONTyped(json, false);
}

export function TemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Template {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'data': TemplateDataFromJSON(json['data']),
        'visibility': TemplateVisibilityFromJSON(json['visibility']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
    };
}

export function TemplateToJSON(value?: Template | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'data': TemplateDataToJSON(value.data),
        'visibility': TemplateVisibilityToJSON(value.visibility),
    };
}


