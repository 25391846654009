/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditLogEntryType,
    AuditLogEntryTypeFromJSON,
    AuditLogEntryTypeFromJSONTyped,
    AuditLogEntryTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuditLogEntry
 */
export interface AuditLogEntry {
    /**
     * Entity identifier
     * @type {string}
     * @memberof AuditLogEntry
     */
    readonly id?: string;
    /**
     * Id of creator user
     * @type {string}
     * @memberof AuditLogEntry
     */
    readonly userId?: string;
    /**
     * Id of the reply
     * @type {string}
     * @memberof AuditLogEntry
     */
    readonly replyId?: string;
    /**
     * Id of the attachment
     * @type {string}
     * @memberof AuditLogEntry
     */
    readonly attachmentId?: string;
    /**
     * Time of accessing the reply
     * @type {Date}
     * @memberof AuditLogEntry
     */
    readonly createdAt?: Date;
    /**
     * Description of the event
     * @type {string}
     * @memberof AuditLogEntry
     */
    readonly message?: string;
    /**
     * 
     * @type {AuditLogEntryType}
     * @memberof AuditLogEntry
     */
    logEntryType?: AuditLogEntryType;
}

export function AuditLogEntryFromJSON(json: any): AuditLogEntry {
    return AuditLogEntryFromJSONTyped(json, false);
}

export function AuditLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'attachmentId': !exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'logEntryType': !exists(json, 'logEntryType') ? undefined : AuditLogEntryTypeFromJSON(json['logEntryType']),
    };
}

export function AuditLogEntryToJSON(value?: AuditLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logEntryType': AuditLogEntryTypeToJSON(value.logEntryType),
    };
}


