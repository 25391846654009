/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldRule,
    FieldRuleFromJSON,
    FieldRuleFromJSONTyped,
    FieldRuleToJSON,
} from './';

/**
 * Email notification
 * @export
 * @interface EmailNotification
 */
export interface EmailNotification {
    /**
     * 
     * @type {string}
     * @memberof EmailNotification
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailNotification
     */
    subjectTemplate: string;
    /**
     * 
     * @type {string}
     * @memberof EmailNotification
     */
    contentTemplate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailNotification
     */
    emails: Array<string>;
    /**
     * 
     * @type {FieldRule}
     * @memberof EmailNotification
     */
    notifyIf?: FieldRule;
}

export function EmailNotificationFromJSON(json: any): EmailNotification {
    return EmailNotificationFromJSONTyped(json, false);
}

export function EmailNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subjectTemplate': json['subjectTemplate'],
        'contentTemplate': json['contentTemplate'],
        'emails': json['emails'],
        'notifyIf': !exists(json, 'notifyIf') ? undefined : FieldRuleFromJSON(json['notifyIf']),
    };
}

export function EmailNotificationToJSON(value?: EmailNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subjectTemplate': value.subjectTemplate,
        'contentTemplate': value.contentTemplate,
        'emails': value.emails,
        'notifyIf': FieldRuleToJSON(value.notifyIf),
    };
}


