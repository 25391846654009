/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Form manager user group.
 * @export
 * @interface MetaformMemberGroup
 */
export interface MetaformMemberGroup {
    /**
     * Id of the manager group in the Keycloak
     * @type {string}
     * @memberof MetaformMemberGroup
     */
    readonly id?: string;
    /**
     * Display of the manager group
     * @type {string}
     * @memberof MetaformMemberGroup
     */
    displayName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetaformMemberGroup
     */
    memberIds: Array<string>;
}

export function MetaformMemberGroupFromJSON(json: any): MetaformMemberGroup {
    return MetaformMemberGroupFromJSONTyped(json, false);
}

export function MetaformMemberGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformMemberGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'memberIds': json['memberIds'],
    };
}

export function MetaformMemberGroupToJSON(value?: MetaformMemberGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'memberIds': value.memberIds,
    };
}


