/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldRule,
    FieldRuleFromJSON,
    FieldRuleFromJSONTyped,
    FieldRuleToJSON,
    MetaformFieldAutocomplete,
    MetaformFieldAutocompleteFromJSON,
    MetaformFieldAutocompleteFromJSONTyped,
    MetaformFieldAutocompleteToJSON,
    MetaformFieldFlags,
    MetaformFieldFlagsFromJSON,
    MetaformFieldFlagsFromJSONTyped,
    MetaformFieldFlagsToJSON,
    MetaformFieldOption,
    MetaformFieldOptionFromJSON,
    MetaformFieldOptionFromJSONTyped,
    MetaformFieldOptionToJSON,
    MetaformFieldSchedule,
    MetaformFieldScheduleFromJSON,
    MetaformFieldScheduleFromJSONTyped,
    MetaformFieldScheduleToJSON,
    MetaformFieldSource,
    MetaformFieldSourceFromJSON,
    MetaformFieldSourceFromJSONTyped,
    MetaformFieldSourceToJSON,
    MetaformFieldTable,
    MetaformFieldTableFromJSON,
    MetaformFieldTableFromJSONTyped,
    MetaformFieldTableToJSON,
    MetaformFieldType,
    MetaformFieldTypeFromJSON,
    MetaformFieldTypeFromJSONTyped,
    MetaformFieldTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MetaformField
 */
export interface MetaformField {
    /**
     * 
     * @type {FieldRule}
     * @memberof MetaformField
     */
    visibleIf?: FieldRule;
    /**
     * Field name
     * @type {string}
     * @memberof MetaformField
     */
    name?: string;
    /**
     * 
     * @type {MetaformFieldType}
     * @memberof MetaformField
     */
    type: MetaformFieldType;
    /**
     * 
     * @type {string}
     * @memberof MetaformField
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetaformField
     */
    required?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetaformField
     */
    contexts?: Array<string>;
    /**
     * 
     * @type {MetaformFieldFlags}
     * @memberof MetaformField
     */
    flags?: MetaformFieldFlags;
    /**
     * 
     * @type {string}
     * @memberof MetaformField
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetaformField
     */
    readonly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MetaformField
     */
    help?: string;
    /**
     * a default value for a field
     * @type {string}
     * @memberof MetaformField
     */
    _default?: string;
    /**
     * Minimum value for a field. Only for number fields
     * @type {number}
     * @memberof MetaformField
     */
    min?: number;
    /**
     * Maximum value for a field. Only for number fields
     * @type {number}
     * @memberof MetaformField
     */
    max?: number;
    /**
     * Value step for a field. Only for number fields
     * @type {number}
     * @memberof MetaformField
     */
    step?: number;
    /**
     * Whether checkbox should be checked by default. Only for checkbox fields
     * @type {boolean}
     * @memberof MetaformField
     */
    checked?: boolean;
    /**
     * Options for radio, checklist, select fields
     * @type {Array<MetaformFieldOption>}
     * @memberof MetaformField
     */
    options?: Array<MetaformFieldOption>;
    /**
     * 
     * @type {MetaformFieldAutocomplete}
     * @memberof MetaformField
     */
    autocomplete?: MetaformFieldAutocomplete;
    /**
     * 
     * @type {MetaformFieldTable}
     * @memberof MetaformField
     */
    table?: MetaformFieldTable;
    /**
     * Text for small field.
     * @type {string}
     * @memberof MetaformField
     */
    text?: string;
    /**
     * Html code for html field.
     * @type {string}
     * @memberof MetaformField
     */
    html?: string;
    /**
     * 
     * @type {MetaformFieldSource}
     * @memberof MetaformField
     */
    source?: MetaformFieldSource;
    /**
     * Show workdays only for date/date-time field.
     * @type {boolean}
     * @memberof MetaformField
     */
    workdaysOnly?: boolean;
    /**
     * Allow past days for date/date-time field.
     * @type {boolean}
     * @memberof MetaformField
     */
    allowPastDays?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetaformField
     */
    classifiers?: Array<string>;
    /**
     * 
     * @type {MetaformFieldSchedule}
     * @memberof MetaformField
     */
    schedule?: MetaformFieldSchedule;
}

export function MetaformFieldFromJSON(json: any): MetaformField {
    return MetaformFieldFromJSONTyped(json, false);
}

export function MetaformFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visibleIf': !exists(json, 'visibleIf') ? undefined : FieldRuleFromJSON(json['visibleIf']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': MetaformFieldTypeFromJSON(json['type']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'contexts': !exists(json, 'contexts') ? undefined : json['contexts'],
        'flags': !exists(json, 'flags') ? undefined : MetaformFieldFlagsFromJSON(json['flags']),
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'readonly': !exists(json, 'readonly') ? undefined : json['readonly'],
        'help': !exists(json, 'help') ? undefined : json['help'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'step': !exists(json, 'step') ? undefined : json['step'],
        'checked': !exists(json, 'checked') ? undefined : json['checked'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(MetaformFieldOptionFromJSON)),
        'autocomplete': !exists(json, 'autocomplete') ? undefined : MetaformFieldAutocompleteFromJSON(json['autocomplete']),
        'table': !exists(json, 'table') ? undefined : MetaformFieldTableFromJSON(json['table']),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'html': !exists(json, 'html') ? undefined : json['html'],
        'source': !exists(json, 'source') ? undefined : MetaformFieldSourceFromJSON(json['source']),
        'workdaysOnly': !exists(json, 'workdaysOnly') ? undefined : json['workdaysOnly'],
        'allowPastDays': !exists(json, 'allowPastDays') ? undefined : json['allowPastDays'],
        'classifiers': !exists(json, 'classifiers') ? undefined : json['classifiers'],
        'schedule': !exists(json, 'schedule') ? undefined : MetaformFieldScheduleFromJSON(json['schedule']),
    };
}

export function MetaformFieldToJSON(value?: MetaformField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visibleIf': FieldRuleToJSON(value.visibleIf),
        'name': value.name,
        'type': MetaformFieldTypeToJSON(value.type),
        'title': value.title,
        'required': value.required,
        'contexts': value.contexts,
        'flags': MetaformFieldFlagsToJSON(value.flags),
        'placeholder': value.placeholder,
        'readonly': value.readonly,
        'help': value.help,
        'default': value._default,
        'min': value.min,
        'max': value.max,
        'step': value.step,
        'checked': value.checked,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(MetaformFieldOptionToJSON)),
        'autocomplete': MetaformFieldAutocompleteToJSON(value.autocomplete),
        'table': MetaformFieldTableToJSON(value.table),
        'text': value.text,
        'html': value.html,
        'source': MetaformFieldSourceToJSON(value.source),
        'workdaysOnly': value.workdaysOnly,
        'allowPastDays': value.allowPastDays,
        'classifiers': value.classifiers,
        'schedule': MetaformFieldScheduleToJSON(value.schedule),
    };
}


