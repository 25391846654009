/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaformStatistics
 */
export interface MetaformStatistics {
    /**
     * 
     * @type {string}
     * @memberof MetaformStatistics
     */
    metaformId?: string;
    /**
     * 
     * @type {Date}
     * @memberof MetaformStatistics
     */
    lastReplyDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof MetaformStatistics
     */
    unprocessedReplies?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaformStatistics
     */
    averageMonthlyReplies?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaformStatistics
     */
    averageReplyProcessDelay?: number;
}

export function MetaformStatisticsFromJSON(json: any): MetaformStatistics {
    return MetaformStatisticsFromJSONTyped(json, false);
}

export function MetaformStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaformId': !exists(json, 'metaformId') ? undefined : json['metaformId'],
        'lastReplyDate': !exists(json, 'lastReplyDate') ? undefined : (new Date(json['lastReplyDate'])),
        'unprocessedReplies': !exists(json, 'unprocessedReplies') ? undefined : json['unprocessedReplies'],
        'averageMonthlyReplies': !exists(json, 'averageMonthlyReplies') ? undefined : json['averageMonthlyReplies'],
        'averageReplyProcessDelay': !exists(json, 'averageReplyProcessDelay') ? undefined : json['averageReplyProcessDelay'],
    };
}

export function MetaformStatisticsToJSON(value?: MetaformStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaformId': value.metaformId,
        'lastReplyDate': value.lastReplyDate === undefined ? undefined : (value.lastReplyDate.toISOString()),
        'unprocessedReplies': value.unprocessedReplies,
        'averageMonthlyReplies': value.averageMonthlyReplies,
        'averageReplyProcessDelay': value.averageReplyProcessDelay,
    };
}


