/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Conflict,
    ConflictFromJSON,
    ConflictToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    Template,
    TemplateFromJSON,
    TemplateToJSON,
    TemplateVisibility,
    TemplateVisibilityFromJSON,
    TemplateVisibilityToJSON,
} from '../models';

export interface CreateTemplateRequest {
    template: Template;
}

export interface DeleteTemplateRequest {
    templateId: string;
}

export interface FindTemplateRequest {
    templateId: string;
}

export interface ListTemplatesRequest {
    visibility?: TemplateVisibility;
}

export interface UpdateTemplateRequest {
    template: Template;
    templateId: string;
}

/**
 * 
 */
export class TemplatesApi extends runtime.BaseAPI {

    /**
     * Creates new Template  Method is allowed for system administrators and metaform administrators 
     * create new Template
     */
    async createTemplateRaw(requestParameters: CreateTemplateRequest): Promise<runtime.ApiResponse<Template>> {
        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling createTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateToJSON(requestParameters.template),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * Creates new Template  Method is allowed for system administrators and metaform administrators 
     * create new Template
     */
    async createTemplate(requestParameters: CreateTemplateRequest): Promise<Template> {
        const response = await this.createTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates new Template  Method is allowed for system administrators and metaform administrators 
     * create new Template
     */
    async createTemplateWithHeaders(requestParameters: CreateTemplateRequest): Promise<[ Template, Headers ]> {
        const response = await this.createTemplateRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes Template  Method is allowed for system administrators and metaform administrators 
     * Deletes Template
     */
    async deleteTemplateRaw(requestParameters: DeleteTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling deleteTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/templates/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes Template  Method is allowed for system administrators and metaform administrators 
     * Deletes Template
     */
    async deleteTemplate(requestParameters: DeleteTemplateRequest): Promise<void> {
        await this.deleteTemplateRaw(requestParameters);
    }

    /**
     * Deletes Template  Method is allowed for system administrators and metaform administrators 
     * Deletes Template
     */
    async deleteTemplateWithHeaders(requestParameters: DeleteTemplateRequest): Promise<Headers> {
        const response = await this.deleteTemplateRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds a Template by slug or id  Method is allowed for system administrators and metaform administrators 
     * Finds single Template
     */
    async findTemplateRaw(requestParameters: FindTemplateRequest): Promise<runtime.ApiResponse<Template>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling findTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/templates/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * Finds a Template by slug or id  Method is allowed for system administrators and metaform administrators 
     * Finds single Template
     */
    async findTemplate(requestParameters: FindTemplateRequest): Promise<Template> {
        const response = await this.findTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds a Template by slug or id  Method is allowed for system administrators and metaform administrators 
     * Finds single Template
     */
    async findTemplateWithHeaders(requestParameters: FindTemplateRequest): Promise<[ Template, Headers ]> {
        const response = await this.findTemplateRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists Templates.  Method is allowed for system administrators and metaform administrators 
     * Lists Templates
     */
    async listTemplatesRaw(requestParameters: ListTemplatesRequest): Promise<runtime.ApiResponse<Array<Template>>> {
        const queryParameters: any = {};

        if (requestParameters.visibility !== undefined) {
            queryParameters['visibility'] = requestParameters.visibility;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateFromJSON));
    }

    /**
     * Lists Templates.  Method is allowed for system administrators and metaform administrators 
     * Lists Templates
     */
    async listTemplates(requestParameters: ListTemplatesRequest = {}): Promise<Array<Template>> {
        const response = await this.listTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists Templates.  Method is allowed for system administrators and metaform administrators 
     * Lists Templates
     */
    async listTemplatesWithHeaders(requestParameters: ListTemplatesRequest): Promise<[ Array<Template>, Headers ]> {
        const response = await this.listTemplatesRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates Template  Method is allowed for system administrators and metaform administrators 
     * Updates Template
     */
    async updateTemplateRaw(requestParameters: UpdateTemplateRequest): Promise<runtime.ApiResponse<Template>> {
        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling updateTemplate.');
        }

        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling updateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/templates/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateToJSON(requestParameters.template),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * Updates Template  Method is allowed for system administrators and metaform administrators 
     * Updates Template
     */
    async updateTemplate(requestParameters: UpdateTemplateRequest): Promise<Template> {
        const response = await this.updateTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates Template  Method is allowed for system administrators and metaform administrators 
     * Updates Template
     */
    async updateTemplateWithHeaders(requestParameters: UpdateTemplateRequest): Promise<[ Template, Headers ]> {
        const response = await this.updateTemplateRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
