/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Conflict,
    ConflictFromJSON,
    ConflictToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface CreateUserRequest {
    user: User;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface FindUserRequest {
    userId: string;
}

export interface ListUsersRequest {
    search?: string;
}

export interface UpdateUserRequest {
    user: User;
    userId: string;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Creates new User  Method is allowed for system administrators and administrators of any metaforms. 
     * create new User
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Creates new User  Method is allowed for system administrators and administrators of any metaforms. 
     * create new User
     */
    async createUser(requestParameters: CreateUserRequest): Promise<User> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates new User  Method is allowed for system administrators and administrators of any metaforms. 
     * create new User
     */
    async createUserWithHeaders(requestParameters: CreateUserRequest): Promise<[ User, Headers ]> {
        const response = await this.createUserRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes User  Method is allowed for system administrators 
     * Deletes User
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes User  Method is allowed for system administrators 
     * Deletes User
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * Deletes User  Method is allowed for system administrators 
     * Deletes User
     */
    async deleteUserWithHeaders(requestParameters: DeleteUserRequest): Promise<Headers> {
        const response = await this.deleteUserRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds User by id.  Method is allowed for system administrators and administrators of any metaforms. 
     * Finds single user
     */
    async findUserRaw(requestParameters: FindUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling findUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Finds User by id.  Method is allowed for system administrators and administrators of any metaforms. 
     * Finds single user
     */
    async findUser(requestParameters: FindUserRequest): Promise<User> {
        const response = await this.findUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds User by id.  Method is allowed for system administrators and administrators of any metaforms. 
     * Finds single user
     */
    async findUserWithHeaders(requestParameters: FindUserRequest): Promise<[ User, Headers ]> {
        const response = await this.findUserRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists Users. When searching users the search will also search from configured federated sources. When user is found from a federated source but does not exist on the user database, user without id is returned.  Method is allowed for system administrators and administrators of any metaforms. 
     * Lists Users
     */
    async listUsersRaw(requestParameters: ListUsersRequest): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Lists Users. When searching users the search will also search from configured federated sources. When user is found from a federated source but does not exist on the user database, user without id is returned.  Method is allowed for system administrators and administrators of any metaforms. 
     * Lists Users
     */
    async listUsers(requestParameters: ListUsersRequest = {}): Promise<Array<User>> {
        const response = await this.listUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists Users. When searching users the search will also search from configured federated sources. When user is found from a federated source but does not exist on the user database, user without id is returned.  Method is allowed for system administrators and administrators of any metaforms. 
     * Lists Users
     */
    async listUsersWithHeaders(requestParameters: ListUsersRequest): Promise<[ Array<User>, Headers ]> {
        const response = await this.listUsersRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates User  Method is allowed for system administrators and administrators of any metaforms. 
     * Updates User
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling updateUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Updates User  Method is allowed for system administrators and administrators of any metaforms. 
     * Updates User
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<User> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates User  Method is allowed for system administrators and administrators of any metaforms. 
     * Updates User
     */
    async updateUserWithHeaders(requestParameters: UpdateUserRequest): Promise<[ User, Headers ]> {
        const response = await this.updateUserRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
