/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TemplateVisibility {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
}

export function TemplateVisibilityFromJSON(json: any): TemplateVisibility {
    return TemplateVisibilityFromJSONTyped(json, false);
}

export function TemplateVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateVisibility {
    return json as TemplateVisibility;
}

export function TemplateVisibilityToJSON(value?: TemplateVisibility | null): any {
    return value as any;
}

