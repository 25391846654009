/* tslint:disable */
/* eslint-disable */
/**
 * CodeServer REST API
 * CodeServer REST API v6.0
 *
 * The version of the OpenAPI document: 6.0
 * Contact: codeserver-support@elisa.fi
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Concept code status
 * @export
 * @enum {string}
 */
export enum Status {
    Proposal = 'PROPOSAL',
    Active = 'ACTIVE',
    Deleted = 'DELETED'
}

export function StatusFromJSON(json: any): Status {
    return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
    return json as Status;
}

export function StatusToJSON(value?: Status | null): any {
    return value as any;
}

