/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaformVersionType {
    Archived = 'ARCHIVED',
    Draft = 'DRAFT'
}

export function MetaformVersionTypeFromJSON(json: any): MetaformVersionType {
    return MetaformVersionTypeFromJSONTyped(json, false);
}

export function MetaformVersionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformVersionType {
    return json as MetaformVersionType;
}

export function MetaformVersionTypeToJSON(value?: MetaformVersionType | null): any {
    return value as any;
}

