/* tslint:disable */
/* eslint-disable */
/**
 * CodeServer REST API
 * CodeServer REST API v6.0
 *
 * The version of the OpenAPI document: 6.0
 * Contact: codeserver-support@elisa.fi
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConceptCode,
    ConceptCodeFromJSON,
    ConceptCodeToJSON,
    ConceptCodeCreated,
    ConceptCodeCreatedFromJSON,
    ConceptCodeCreatedToJSON,
    ConceptCodes,
    ConceptCodesFromJSON,
    ConceptCodesToJSON,
    Qfield,
    QfieldFromJSON,
    QfieldToJSON,
    SortBy,
    SortByFromJSON,
    SortByToJSON,
    Status,
    StatusFromJSON,
    StatusToJSON,
} from '../models';

export interface DeleteConceptCodeFromDefaultVersionRequest {
    classificationId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface DeleteConceptCodeFromSpecifiedVersionRequest {
    classificationId: string;
    versionId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface GetConceptCodeFromDefaultVersionRequest {
    classificationId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface GetConceptCodeFromSpecifiedVersionRequest {
    classificationId: string;
    versionId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface GetConceptCodesFromDefaultVersionRequest {
    classificationId: string;
    status?: Array<Status>;
    validOn?: Date;
    qvalue?: string;
    qfield?: Array<Qfield>;
    modifiedAfter?: string;
    sortBy?: SortBy;
    pageSize?: number;
    page?: number;
}

export interface GetConceptCodesFromSpecifiedVersionRequest {
    classificationId: string;
    versionId: string;
    status?: Array<Status>;
    validOn?: Date;
    qvalue?: string;
    qfield?: Array<Qfield>;
    modifiedAfter?: string;
    sortBy?: SortBy;
    pageSize?: number;
    page?: number;
}

export interface PatchConceptCodeInDefaultVersionRequest {
    conceptCode: ConceptCode;
    classificationId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface PatchConceptCodeInSpecifiedVersionRequest {
    conceptCode: ConceptCode;
    classificationId: string;
    versionId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface PostConceptCodeToDefaultVersionRequest {
    conceptCode: ConceptCode;
    classificationId: string;
}

export interface PostConceptCodeToSpecifiedVersionRequest {
    conceptCode: ConceptCode;
    classificationId: string;
    versionId: string;
}

export interface PutConceptCodeInDefaultVersionRequest {
    conceptCode: ConceptCode;
    classificationId: string;
    conceptCodeId: string;
    validOn?: Date;
}

export interface PutConceptCodeInSpecifiedVersionRequest {
    conceptCode: ConceptCode;
    classificationId: string;
    versionId: string;
    conceptCodeId: string;
    validOn?: Date;
}

/**
 * 
 */
export class ConceptCodeApi extends runtime.BaseAPI {

    /**
     * Delete (i.e. change status to DELETED) single active concept code of specified classification and it\'s default version. Use of this sevice requires authentication.
     * Delete concept code by classification id and concept code id.
     */
    async deleteConceptCodeFromDefaultVersionRaw(requestParameters: DeleteConceptCodeFromDefaultVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling deleteConceptCodeFromDefaultVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling deleteConceptCodeFromDefaultVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete (i.e. change status to DELETED) single active concept code of specified classification and it\'s default version. Use of this sevice requires authentication.
     * Delete concept code by classification id and concept code id.
     */
    async deleteConceptCodeFromDefaultVersion(requestParameters: DeleteConceptCodeFromDefaultVersionRequest): Promise<void> {
        await this.deleteConceptCodeFromDefaultVersionRaw(requestParameters);
    }

    /**
     * Delete (i.e. change status to DELETED) single active concept code of specified classification and it\'s default version. Use of this sevice requires authentication.
     * Delete concept code by classification id and concept code id.
     */
    async deleteConceptCodeFromDefaultVersionWithHeaders(requestParameters: DeleteConceptCodeFromDefaultVersionRequest): Promise<Headers> {
        const response = await this.deleteConceptCodeFromDefaultVersionRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Delete (i.e. change status to DELETED) single active concept code of specified classification and version. Use of this sevice requires authentication.
     * Delete concept code by classification id, classification version id and concept code id.
     */
    async deleteConceptCodeFromSpecifiedVersionRaw(requestParameters: DeleteConceptCodeFromSpecifiedVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling deleteConceptCodeFromSpecifiedVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling deleteConceptCodeFromSpecifiedVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling deleteConceptCodeFromSpecifiedVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/versions/{versionId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete (i.e. change status to DELETED) single active concept code of specified classification and version. Use of this sevice requires authentication.
     * Delete concept code by classification id, classification version id and concept code id.
     */
    async deleteConceptCodeFromSpecifiedVersion(requestParameters: DeleteConceptCodeFromSpecifiedVersionRequest): Promise<void> {
        await this.deleteConceptCodeFromSpecifiedVersionRaw(requestParameters);
    }

    /**
     * Delete (i.e. change status to DELETED) single active concept code of specified classification and version. Use of this sevice requires authentication.
     * Delete concept code by classification id, classification version id and concept code id.
     */
    async deleteConceptCodeFromSpecifiedVersionWithHeaders(requestParameters: DeleteConceptCodeFromSpecifiedVersionRequest): Promise<Headers> {
        const response = await this.deleteConceptCodeFromSpecifiedVersionRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Get all data of single active concept code of specified classification and it\'s default version.
     * Get concept code by classification id and concept code id.
     */
    async getConceptCodeFromDefaultVersionRaw(requestParameters: GetConceptCodeFromDefaultVersionRequest): Promise<runtime.ApiResponse<ConceptCode>> {
        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling getConceptCodeFromDefaultVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling getConceptCodeFromDefaultVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptCodeFromJSON(jsonValue));
    }

    /**
     * Get all data of single active concept code of specified classification and it\'s default version.
     * Get concept code by classification id and concept code id.
     */
    async getConceptCodeFromDefaultVersion(requestParameters: GetConceptCodeFromDefaultVersionRequest): Promise<ConceptCode> {
        const response = await this.getConceptCodeFromDefaultVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all data of single active concept code of specified classification and it\'s default version.
     * Get concept code by classification id and concept code id.
     */
    async getConceptCodeFromDefaultVersionWithHeaders(requestParameters: GetConceptCodeFromDefaultVersionRequest): Promise<[ ConceptCode, Headers ]> {
        const response = await this.getConceptCodeFromDefaultVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Get all data of single active concept code of specified classification and specified classification version
     * Get concept code by classification id, classification version id and concept code id.
     */
    async getConceptCodeFromSpecifiedVersionRaw(requestParameters: GetConceptCodeFromSpecifiedVersionRequest): Promise<runtime.ApiResponse<ConceptCode>> {
        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling getConceptCodeFromSpecifiedVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling getConceptCodeFromSpecifiedVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling getConceptCodeFromSpecifiedVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/versions/{versionId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptCodeFromJSON(jsonValue));
    }

    /**
     * Get all data of single active concept code of specified classification and specified classification version
     * Get concept code by classification id, classification version id and concept code id.
     */
    async getConceptCodeFromSpecifiedVersion(requestParameters: GetConceptCodeFromSpecifiedVersionRequest): Promise<ConceptCode> {
        const response = await this.getConceptCodeFromSpecifiedVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all data of single active concept code of specified classification and specified classification version
     * Get concept code by classification id, classification version id and concept code id.
     */
    async getConceptCodeFromSpecifiedVersionWithHeaders(requestParameters: GetConceptCodeFromSpecifiedVersionRequest): Promise<[ ConceptCode, Headers ]> {
        const response = await this.getConceptCodeFromSpecifiedVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Get collection of concept codes of specified classification and it\'s default version
     * Get concept codes by classification id.
     */
    async getConceptCodesFromDefaultVersionRaw(requestParameters: GetConceptCodesFromDefaultVersionRequest): Promise<runtime.ApiResponse<ConceptCodes>> {
        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling getConceptCodesFromDefaultVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        if (requestParameters.qvalue !== undefined) {
            queryParameters['qvalue'] = requestParameters.qvalue;
        }

        if (requestParameters.qfield) {
            queryParameters['qfield'] = requestParameters.qfield;
        }

        if (requestParameters.modifiedAfter !== undefined) {
            queryParameters['modifiedAfter'] = requestParameters.modifiedAfter;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/conceptcodes`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptCodesFromJSON(jsonValue));
    }

    /**
     * Get collection of concept codes of specified classification and it\'s default version
     * Get concept codes by classification id.
     */
    async getConceptCodesFromDefaultVersion(requestParameters: GetConceptCodesFromDefaultVersionRequest): Promise<ConceptCodes> {
        const response = await this.getConceptCodesFromDefaultVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get collection of concept codes of specified classification and it\'s default version
     * Get concept codes by classification id.
     */
    async getConceptCodesFromDefaultVersionWithHeaders(requestParameters: GetConceptCodesFromDefaultVersionRequest): Promise<[ ConceptCodes, Headers ]> {
        const response = await this.getConceptCodesFromDefaultVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Get collection of concept codes of specified classification and version
     * Get concept codes by classification id and classification version id.
     */
    async getConceptCodesFromSpecifiedVersionRaw(requestParameters: GetConceptCodesFromSpecifiedVersionRequest): Promise<runtime.ApiResponse<ConceptCodes>> {
        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling getConceptCodesFromSpecifiedVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling getConceptCodesFromSpecifiedVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        if (requestParameters.qvalue !== undefined) {
            queryParameters['qvalue'] = requestParameters.qvalue;
        }

        if (requestParameters.qfield) {
            queryParameters['qfield'] = requestParameters.qfield;
        }

        if (requestParameters.modifiedAfter !== undefined) {
            queryParameters['modifiedAfter'] = requestParameters.modifiedAfter;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/versions/{versionId}/conceptcodes`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptCodesFromJSON(jsonValue));
    }

    /**
     * Get collection of concept codes of specified classification and version
     * Get concept codes by classification id and classification version id.
     */
    async getConceptCodesFromSpecifiedVersion(requestParameters: GetConceptCodesFromSpecifiedVersionRequest): Promise<ConceptCodes> {
        const response = await this.getConceptCodesFromSpecifiedVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get collection of concept codes of specified classification and version
     * Get concept codes by classification id and classification version id.
     */
    async getConceptCodesFromSpecifiedVersionWithHeaders(requestParameters: GetConceptCodesFromSpecifiedVersionRequest): Promise<[ ConceptCodes, Headers ]> {
        const response = await this.getConceptCodesFromSpecifiedVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Partial update of existing concept code in specified classification/default version. Use of this sevice requires authentication.
     * Partial update of existing concept code in the default version of specified classification.
     */
    async patchConceptCodeInDefaultVersionRaw(requestParameters: PatchConceptCodeInDefaultVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conceptCode === null || requestParameters.conceptCode === undefined) {
            throw new runtime.RequiredError('conceptCode','Required parameter requestParameters.conceptCode was null or undefined when calling patchConceptCodeInDefaultVersion.');
        }

        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling patchConceptCodeInDefaultVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling patchConceptCodeInDefaultVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptCodeToJSON(requestParameters.conceptCode),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Partial update of existing concept code in specified classification/default version. Use of this sevice requires authentication.
     * Partial update of existing concept code in the default version of specified classification.
     */
    async patchConceptCodeInDefaultVersion(requestParameters: PatchConceptCodeInDefaultVersionRequest): Promise<void> {
        await this.patchConceptCodeInDefaultVersionRaw(requestParameters);
    }

    /**
     * Partial update of existing concept code in specified classification/default version. Use of this sevice requires authentication.
     * Partial update of existing concept code in the default version of specified classification.
     */
    async patchConceptCodeInDefaultVersionWithHeaders(requestParameters: PatchConceptCodeInDefaultVersionRequest): Promise<Headers> {
        const response = await this.patchConceptCodeInDefaultVersionRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Partial update of existing concept code in specified classification and version. Use of this sevice requires authentication.
     * Partial update of existing concept code in specified classification and version.
     */
    async patchConceptCodeInSpecifiedVersionRaw(requestParameters: PatchConceptCodeInSpecifiedVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conceptCode === null || requestParameters.conceptCode === undefined) {
            throw new runtime.RequiredError('conceptCode','Required parameter requestParameters.conceptCode was null or undefined when calling patchConceptCodeInSpecifiedVersion.');
        }

        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling patchConceptCodeInSpecifiedVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling patchConceptCodeInSpecifiedVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling patchConceptCodeInSpecifiedVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/versions/{versionId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptCodeToJSON(requestParameters.conceptCode),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Partial update of existing concept code in specified classification and version. Use of this sevice requires authentication.
     * Partial update of existing concept code in specified classification and version.
     */
    async patchConceptCodeInSpecifiedVersion(requestParameters: PatchConceptCodeInSpecifiedVersionRequest): Promise<void> {
        await this.patchConceptCodeInSpecifiedVersionRaw(requestParameters);
    }

    /**
     * Partial update of existing concept code in specified classification and version. Use of this sevice requires authentication.
     * Partial update of existing concept code in specified classification and version.
     */
    async patchConceptCodeInSpecifiedVersionWithHeaders(requestParameters: PatchConceptCodeInSpecifiedVersionRequest): Promise<Headers> {
        const response = await this.patchConceptCodeInSpecifiedVersionRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Create new active concept code to the default version of specified classification. Status of the created concept code will be active. Use of this sevice requires authentication.
     * Create new active concept code to the default version of specified classification.
     */
    async postConceptCodeToDefaultVersionRaw(requestParameters: PostConceptCodeToDefaultVersionRequest): Promise<runtime.ApiResponse<ConceptCodeCreated>> {
        if (requestParameters.conceptCode === null || requestParameters.conceptCode === undefined) {
            throw new runtime.RequiredError('conceptCode','Required parameter requestParameters.conceptCode was null or undefined when calling postConceptCodeToDefaultVersion.');
        }

        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling postConceptCodeToDefaultVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/conceptcodes`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptCodeToJSON(requestParameters.conceptCode),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptCodeCreatedFromJSON(jsonValue));
    }

    /**
     * Create new active concept code to the default version of specified classification. Status of the created concept code will be active. Use of this sevice requires authentication.
     * Create new active concept code to the default version of specified classification.
     */
    async postConceptCodeToDefaultVersion(requestParameters: PostConceptCodeToDefaultVersionRequest): Promise<ConceptCodeCreated> {
        const response = await this.postConceptCodeToDefaultVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create new active concept code to the default version of specified classification. Status of the created concept code will be active. Use of this sevice requires authentication.
     * Create new active concept code to the default version of specified classification.
     */
    async postConceptCodeToDefaultVersionWithHeaders(requestParameters: PostConceptCodeToDefaultVersionRequest): Promise<[ ConceptCodeCreated, Headers ]> {
        const response = await this.postConceptCodeToDefaultVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Create new active concept code resource to the specified version of specified classification. Use of this sevice requires authentication.
     * Create new active concept code to the specified version of specified classification.
     */
    async postConceptCodeToSpecifiedVersionRaw(requestParameters: PostConceptCodeToSpecifiedVersionRequest): Promise<runtime.ApiResponse<ConceptCodeCreated>> {
        if (requestParameters.conceptCode === null || requestParameters.conceptCode === undefined) {
            throw new runtime.RequiredError('conceptCode','Required parameter requestParameters.conceptCode was null or undefined when calling postConceptCodeToSpecifiedVersion.');
        }

        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling postConceptCodeToSpecifiedVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling postConceptCodeToSpecifiedVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/versions/{versionId}/conceptcodes`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptCodeToJSON(requestParameters.conceptCode),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptCodeCreatedFromJSON(jsonValue));
    }

    /**
     * Create new active concept code resource to the specified version of specified classification. Use of this sevice requires authentication.
     * Create new active concept code to the specified version of specified classification.
     */
    async postConceptCodeToSpecifiedVersion(requestParameters: PostConceptCodeToSpecifiedVersionRequest): Promise<ConceptCodeCreated> {
        const response = await this.postConceptCodeToSpecifiedVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create new active concept code resource to the specified version of specified classification. Use of this sevice requires authentication.
     * Create new active concept code to the specified version of specified classification.
     */
    async postConceptCodeToSpecifiedVersionWithHeaders(requestParameters: PostConceptCodeToSpecifiedVersionRequest): Promise<[ ConceptCodeCreated, Headers ]> {
        const response = await this.postConceptCodeToSpecifiedVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Update existing concept code in specified classification/default version. Use of this sevice requires authentication.
     * Update existing concept code in the default version of specified classification.
     */
    async putConceptCodeInDefaultVersionRaw(requestParameters: PutConceptCodeInDefaultVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conceptCode === null || requestParameters.conceptCode === undefined) {
            throw new runtime.RequiredError('conceptCode','Required parameter requestParameters.conceptCode was null or undefined when calling putConceptCodeInDefaultVersion.');
        }

        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling putConceptCodeInDefaultVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling putConceptCodeInDefaultVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptCodeToJSON(requestParameters.conceptCode),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update existing concept code in specified classification/default version. Use of this sevice requires authentication.
     * Update existing concept code in the default version of specified classification.
     */
    async putConceptCodeInDefaultVersion(requestParameters: PutConceptCodeInDefaultVersionRequest): Promise<void> {
        await this.putConceptCodeInDefaultVersionRaw(requestParameters);
    }

    /**
     * Update existing concept code in specified classification/default version. Use of this sevice requires authentication.
     * Update existing concept code in the default version of specified classification.
     */
    async putConceptCodeInDefaultVersionWithHeaders(requestParameters: PutConceptCodeInDefaultVersionRequest): Promise<Headers> {
        const response = await this.putConceptCodeInDefaultVersionRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Update existing concept code in the specified classification and specified version. Use of this sevice requires authentication.
     * Update existing concept code in the specified version of specified classification.
     */
    async putConceptCodeInSpecifiedVersionRaw(requestParameters: PutConceptCodeInSpecifiedVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conceptCode === null || requestParameters.conceptCode === undefined) {
            throw new runtime.RequiredError('conceptCode','Required parameter requestParameters.conceptCode was null or undefined when calling putConceptCodeInSpecifiedVersion.');
        }

        if (requestParameters.classificationId === null || requestParameters.classificationId === undefined) {
            throw new runtime.RequiredError('classificationId','Required parameter requestParameters.classificationId was null or undefined when calling putConceptCodeInSpecifiedVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling putConceptCodeInSpecifiedVersion.');
        }

        if (requestParameters.conceptCodeId === null || requestParameters.conceptCodeId === undefined) {
            throw new runtime.RequiredError('conceptCodeId','Required parameter requestParameters.conceptCodeId was null or undefined when calling putConceptCodeInSpecifiedVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.validOn !== undefined) {
            queryParameters['validOn'] = (requestParameters.validOn as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuthScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v6/classifications/{classificationId}/versions/{versionId}/conceptcodes/{conceptCodeId}`.replace(`{${"classificationId"}}`, encodeURIComponent(String(requestParameters.classificationId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))).replace(`{${"conceptCodeId"}}`, encodeURIComponent(String(requestParameters.conceptCodeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConceptCodeToJSON(requestParameters.conceptCode),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update existing concept code in the specified classification and specified version. Use of this sevice requires authentication.
     * Update existing concept code in the specified version of specified classification.
     */
    async putConceptCodeInSpecifiedVersion(requestParameters: PutConceptCodeInSpecifiedVersionRequest): Promise<void> {
        await this.putConceptCodeInSpecifiedVersionRaw(requestParameters);
    }

    /**
     * Update existing concept code in the specified classification and specified version. Use of this sevice requires authentication.
     * Update existing concept code in the specified version of specified classification.
     */
    async putConceptCodeInSpecifiedVersionWithHeaders(requestParameters: PutConceptCodeInSpecifiedVersionRequest): Promise<Headers> {
        const response = await this.putConceptCodeInSpecifiedVersionRaw(requestParameters);
        return response.raw.headers;
    }

}
