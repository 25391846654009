/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaformMemberRole {
    Manager = 'MANAGER',
    Administrator = 'ADMINISTRATOR'
}

export function MetaformMemberRoleFromJSON(json: any): MetaformMemberRole {
    return MetaformMemberRoleFromJSONTyped(json, false);
}

export function MetaformMemberRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformMemberRole {
    return json as MetaformMemberRole;
}

export function MetaformMemberRoleToJSON(value?: MetaformMemberRole | null): any {
    return value as any;
}

