/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaformVisibility {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
}

export function MetaformVisibilityFromJSON(json: any): MetaformVisibility {
    return MetaformVisibilityFromJSONTyped(json, false);
}

export function MetaformVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformVisibility {
    return json as MetaformVisibility;
}

export function MetaformVisibilityToJSON(value?: MetaformVisibility | null): any {
    return value as any;
}

