/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserFederationSource,
    UserFederationSourceFromJSON,
    UserFederationSourceFromJSONTyped,
    UserFederationSourceToJSON,
} from './';

/**
 * User federated identity
 * @export
 * @interface UserFederatedIdentity
 */
export interface UserFederatedIdentity {
    /**
     * 
     * @type {UserFederationSource}
     * @memberof UserFederatedIdentity
     */
    source: UserFederationSource;
    /**
     * 
     * @type {string}
     * @memberof UserFederatedIdentity
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserFederatedIdentity
     */
    username: string;
}

export function UserFederatedIdentityFromJSON(json: any): UserFederatedIdentity {
    return UserFederatedIdentityFromJSONTyped(json, false);
}

export function UserFederatedIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFederatedIdentity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': UserFederationSourceFromJSON(json['source']),
        'userId': json['userId'],
        'username': json['username'],
    };
}

export function UserFederatedIdentityToJSON(value?: UserFederatedIdentity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': UserFederationSourceToJSON(value.source),
        'userId': value.userId,
        'username': value.username,
    };
}


