/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Conflict,
    ConflictFromJSON,
    ConflictToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MetaformVersion,
    MetaformVersionFromJSON,
    MetaformVersionToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models';

export interface CreateMetaformVersionRequest {
    metaformVersion: MetaformVersion;
    metaformId: string;
}

export interface DeleteMetaformVersionRequest {
    metaformId: string;
    versionId: string;
}

export interface FindMetaformVersionRequest {
    metaformId: string;
    versionId: string;
}

export interface ListMetaformVersionsRequest {
    metaformId: string;
}

export interface UpdateMetaformVersionRequest {
    metaformVersion: MetaformVersion;
    metaformId: string;
    versionId: string;
}

/**
 * 
 */
export class VersionsApi extends runtime.BaseAPI {

    /**
     * Creates new Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Create new Metaform version
     */
    async createMetaformVersionRaw(requestParameters: CreateMetaformVersionRequest): Promise<runtime.ApiResponse<MetaformVersion>> {
        if (requestParameters.metaformVersion === null || requestParameters.metaformVersion === undefined) {
            throw new runtime.RequiredError('metaformVersion','Required parameter requestParameters.metaformVersion was null or undefined when calling createMetaformVersion.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling createMetaformVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/versions`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformVersionToJSON(requestParameters.metaformVersion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformVersionFromJSON(jsonValue));
    }

    /**
     * Creates new Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Create new Metaform version
     */
    async createMetaformVersion(requestParameters: CreateMetaformVersionRequest): Promise<MetaformVersion> {
        const response = await this.createMetaformVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates new Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Create new Metaform version
     */
    async createMetaformVersionWithHeaders(requestParameters: CreateMetaformVersionRequest): Promise<[ MetaformVersion, Headers ]> {
        const response = await this.createMetaformVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Deletes Metaform version
     */
    async deleteMetaformVersionRaw(requestParameters: DeleteMetaformVersionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling deleteMetaformVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling deleteMetaformVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/versions/{versionId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Deletes Metaform version
     */
    async deleteMetaformVersion(requestParameters: DeleteMetaformVersionRequest): Promise<void> {
        await this.deleteMetaformVersionRaw(requestParameters);
    }

    /**
     * Deletes a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Deletes Metaform version
     */
    async deleteMetaformVersionWithHeaders(requestParameters: DeleteMetaformVersionRequest): Promise<Headers> {
        const response = await this.deleteMetaformVersionRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Finds Version of Metaform
     */
    async findMetaformVersionRaw(requestParameters: FindMetaformVersionRequest): Promise<runtime.ApiResponse<MetaformVersion>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling findMetaformVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling findMetaformVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/versions/{versionId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformVersionFromJSON(jsonValue));
    }

    /**
     * Finds a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Finds Version of Metaform
     */
    async findMetaformVersion(requestParameters: FindMetaformVersionRequest): Promise<MetaformVersion> {
        const response = await this.findMetaformVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Finds Version of Metaform
     */
    async findMetaformVersionWithHeaders(requestParameters: FindMetaformVersionRequest): Promise<[ MetaformVersion, Headers ]> {
        const response = await this.findMetaformVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists Metaform versions  Method requires that logged user is either system admin or metaform admin 
     * Lists all the versions of Metaform
     */
    async listMetaformVersionsRaw(requestParameters: ListMetaformVersionsRequest): Promise<runtime.ApiResponse<Array<MetaformVersion>>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling listMetaformVersions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/versions`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetaformVersionFromJSON));
    }

    /**
     * Lists Metaform versions  Method requires that logged user is either system admin or metaform admin 
     * Lists all the versions of Metaform
     */
    async listMetaformVersions(requestParameters: ListMetaformVersionsRequest): Promise<Array<MetaformVersion>> {
        const response = await this.listMetaformVersionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists Metaform versions  Method requires that logged user is either system admin or metaform admin 
     * Lists all the versions of Metaform
     */
    async listMetaformVersionsWithHeaders(requestParameters: ListMetaformVersionsRequest): Promise<[ Array<MetaformVersion>, Headers ]> {
        const response = await this.listMetaformVersionsRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Updates MetaformVersion
     */
    async updateMetaformVersionRaw(requestParameters: UpdateMetaformVersionRequest): Promise<runtime.ApiResponse<MetaformVersion>> {
        if (requestParameters.metaformVersion === null || requestParameters.metaformVersion === undefined) {
            throw new runtime.RequiredError('metaformVersion','Required parameter requestParameters.metaformVersion was null or undefined when calling updateMetaformVersion.');
        }

        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling updateMetaformVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling updateMetaformVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/versions/{versionId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MetaformVersionToJSON(requestParameters.metaformVersion),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaformVersionFromJSON(jsonValue));
    }

    /**
     * Updates a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Updates MetaformVersion
     */
    async updateMetaformVersion(requestParameters: UpdateMetaformVersionRequest): Promise<MetaformVersion> {
        const response = await this.updateMetaformVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a Metaform version  Method requires that logged user is either system admin or metaform admin 
     * Updates MetaformVersion
     */
    async updateMetaformVersionWithHeaders(requestParameters: UpdateMetaformVersionRequest): Promise<[ MetaformVersion, Headers ]> {
        const response = await this.updateMetaformVersionRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
