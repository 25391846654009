/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserFederatedIdentity,
    UserFederatedIdentityFromJSON,
    UserFederatedIdentityFromJSONTyped,
    UserFederatedIdentityToJSON,
} from './';

/**
 * Form member or manager user.
 * @export
 * @interface User
 */
export interface User {
    /**
     * Id of the form member or manager user in Keycloak.
     * @type {string}
     * @memberof User
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * Human readable name for user. Display name is customized for each fededated identity. For example card authentication sources usually display card serial numbers as part of the name.
     * @type {string}
     * @memberof User
     */
    readonly displayName?: string;
    /**
     * 
     * @type {Array<UserFederatedIdentity>}
     * @memberof User
     */
    federatedIdentities?: Array<UserFederatedIdentity>;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'username': json['username'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'federatedIdentities': !exists(json, 'federatedIdentities') ? undefined : ((json['federatedIdentities'] as Array<any>).map(UserFederatedIdentityFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'federatedIdentities': value.federatedIdentities === undefined ? undefined : ((value.federatedIdentities as Array<any>).map(UserFederatedIdentityToJSON)),
    };
}


