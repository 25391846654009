/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetaformSection,
    MetaformSectionFromJSON,
    MetaformSectionFromJSONTyped,
    MetaformSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TemplateData
 */
export interface TemplateData {
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    exportThemeId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    title?: string;
    /**
     * 
     * @type {Array<MetaformSection>}
     * @memberof TemplateData
     */
    sections?: Array<MetaformSection>;
}

export function TemplateDataFromJSON(json: any): TemplateData {
    return TemplateDataFromJSONTyped(json, false);
}

export function TemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportThemeId': !exists(json, 'exportThemeId') ? undefined : json['exportThemeId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(MetaformSectionFromJSON)),
    };
}

export function TemplateDataToJSON(value?: TemplateData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportThemeId': value.exportThemeId,
        'title': value.title,
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(MetaformSectionToJSON)),
    };
}


