/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetaformTableColumn,
    MetaformTableColumnFromJSON,
    MetaformTableColumnFromJSONTyped,
    MetaformTableColumnToJSON,
} from './';

/**
 * Table field options
 * @export
 * @interface MetaformFieldTable
 */
export interface MetaformFieldTable {
    /**
     * Defines whether user can add more table rows.
     * @type {boolean}
     * @memberof MetaformFieldTable
     */
    addRows?: boolean;
    /**
     * Columns for table fields
     * @type {Array<MetaformTableColumn>}
     * @memberof MetaformFieldTable
     */
    columns?: Array<MetaformTableColumn>;
}

export function MetaformFieldTableFromJSON(json: any): MetaformFieldTable {
    return MetaformFieldTableFromJSONTyped(json, false);
}

export function MetaformFieldTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addRows': !exists(json, 'addRows') ? undefined : json['addRows'],
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(MetaformTableColumnFromJSON)),
    };
}

export function MetaformFieldTableToJSON(value?: MetaformFieldTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addRows': value.addRows,
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(MetaformTableColumnToJSON)),
    };
}


