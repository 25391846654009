/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuditLogEntry,
    AuditLogEntryFromJSON,
    AuditLogEntryToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models';

export interface DeleteAuditLogEntryRequest {
    metaformId: string;
    auditLogEntryId: string;
}

export interface ListAuditLogEntriesRequest {
    metaformId: string;
    userId?: string;
    replyId?: string;
    createdBefore?: string;
    createdAfter?: string;
}

/**
 * 
 */
export class AuditLogEntriesApi extends runtime.BaseAPI {

    /**
     * Deletes an audit log. Method is allowed only when system is running in test mode 
     * Deletes an audit log
     */
    async deleteAuditLogEntryRaw(requestParameters: DeleteAuditLogEntryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling deleteAuditLogEntry.');
        }

        if (requestParameters.auditLogEntryId === null || requestParameters.auditLogEntryId === undefined) {
            throw new runtime.RequiredError('auditLogEntryId','Required parameter requestParameters.auditLogEntryId was null or undefined when calling deleteAuditLogEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/auditLogEntries/{auditLogEntryId}`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))).replace(`{${"auditLogEntryId"}}`, encodeURIComponent(String(requestParameters.auditLogEntryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an audit log. Method is allowed only when system is running in test mode 
     * Deletes an audit log
     */
    async deleteAuditLogEntry(requestParameters: DeleteAuditLogEntryRequest): Promise<void> {
        await this.deleteAuditLogEntryRaw(requestParameters);
    }

    /**
     * Deletes an audit log. Method is allowed only when system is running in test mode 
     * Deletes an audit log
     */
    async deleteAuditLogEntryWithHeaders(requestParameters: DeleteAuditLogEntryRequest): Promise<Headers> {
        const response = await this.deleteAuditLogEntryRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Lists audit log entries for metaform  Method requires that logged user is system admin 
     * Lists audit log entries for metaform
     */
    async listAuditLogEntriesRaw(requestParameters: ListAuditLogEntriesRequest): Promise<runtime.ApiResponse<Array<AuditLogEntry>>> {
        if (requestParameters.metaformId === null || requestParameters.metaformId === undefined) {
            throw new runtime.RequiredError('metaformId','Required parameter requestParameters.metaformId was null or undefined when calling listAuditLogEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.replyId !== undefined) {
            queryParameters['replyId'] = requestParameters.replyId;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/metaforms/{metaformId}/auditLogEntries`.replace(`{${"metaformId"}}`, encodeURIComponent(String(requestParameters.metaformId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditLogEntryFromJSON));
    }

    /**
     * Lists audit log entries for metaform  Method requires that logged user is system admin 
     * Lists audit log entries for metaform
     */
    async listAuditLogEntries(requestParameters: ListAuditLogEntriesRequest): Promise<Array<AuditLogEntry>> {
        const response = await this.listAuditLogEntriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists audit log entries for metaform  Method requires that logged user is system admin 
     * Lists audit log entries for metaform
     */
    async listAuditLogEntriesWithHeaders(requestParameters: ListAuditLogEntriesRequest): Promise<[ Array<AuditLogEntry>, Headers ]> {
        const response = await this.listAuditLogEntriesRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
