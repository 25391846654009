/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaformFieldType {
    Text = 'text',
    Url = 'url',
    Hidden = 'hidden',
    Email = 'email',
    Number = 'number',
    Slider = 'slider',
    Memo = 'memo',
    Boolean = 'boolean',
    Radio = 'radio',
    Checklist = 'checklist',
    Date = 'date',
    Time = 'time',
    DateTime = 'date-time',
    Files = 'files',
    Table = 'table',
    Logo = 'logo',
    SmallText = 'small-text',
    Html = 'html',
    Submit = 'submit',
    Select = 'select',
    Autocomplete = 'autocomplete',
    AutocompleteMultiple = 'autocomplete-multiple'
}

export function MetaformFieldTypeFromJSON(json: any): MetaformFieldType {
    return MetaformFieldTypeFromJSONTyped(json, false);
}

export function MetaformFieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldType {
    return json as MetaformFieldType;
}

export function MetaformFieldTypeToJSON(value?: MetaformFieldType | null): any {
    return value as any;
}

