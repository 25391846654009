/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Values for enum columns
 * @export
 * @interface MetaformTableColumnValues
 */
export interface MetaformTableColumnValues {
    /**
     * 
     * @type {string}
     * @memberof MetaformTableColumnValues
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaformTableColumnValues
     */
    other?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaformTableColumnValues
     */
    text?: string;
}

export function MetaformTableColumnValuesFromJSON(json: any): MetaformTableColumnValues {
    return MetaformTableColumnValuesFromJSONTyped(json, false);
}

export function MetaformTableColumnValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformTableColumnValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'other': !exists(json, 'other') ? undefined : json['other'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function MetaformTableColumnValuesToJSON(value?: MetaformTableColumnValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'other': value.other,
        'text': value.text,
    };
}


