/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaformFieldAutocompleteOptions
 */
export interface MetaformFieldAutocompleteOptions {
    /**
     * Base URL for code server based services
     * @type {string}
     * @memberof MetaformFieldAutocompleteOptions
     */
    codeServerBaseUrl?: string;
    /**
     * Classification id for Code Server services
     * @type {string}
     * @memberof MetaformFieldAutocompleteOptions
     */
    codeServerClassificationId?: string;
    /**
     * Parent concept code id for Code Server services
     * @type {string}
     * @memberof MetaformFieldAutocompleteOptions
     */
    codeServerParentConceptCodeId?: string;
}

export function MetaformFieldAutocompleteOptionsFromJSON(json: any): MetaformFieldAutocompleteOptions {
    return MetaformFieldAutocompleteOptionsFromJSONTyped(json, false);
}

export function MetaformFieldAutocompleteOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldAutocompleteOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeServerBaseUrl': !exists(json, 'codeServerBaseUrl') ? undefined : json['codeServerBaseUrl'],
        'codeServerClassificationId': !exists(json, 'codeServerClassificationId') ? undefined : json['codeServerClassificationId'],
        'codeServerParentConceptCodeId': !exists(json, 'codeServerParentConceptCodeId') ? undefined : json['codeServerParentConceptCodeId'],
    };
}

export function MetaformFieldAutocompleteOptionsToJSON(value?: MetaformFieldAutocompleteOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeServerBaseUrl': value.codeServerBaseUrl,
        'codeServerClassificationId': value.codeServerClassificationId,
        'codeServerParentConceptCodeId': value.codeServerParentConceptCodeId,
    };
}


