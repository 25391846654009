/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    Script,
    ScriptFromJSON,
    ScriptToJSON,
} from '../models';

export interface CreateScriptRequest {
    script: Script;
}

export interface DeleteScriptRequest {
    scriptId: string;
}

export interface FindScriptRequest {
    scriptId: string;
}

export interface UpdateScriptRequest {
    script: Script;
    scriptId: string;
}

/**
 * 
 */
export class ScriptsApi extends runtime.BaseAPI {

    /**
     * Creates a new script  Method is allowed only for system administrators 
     * Create new script
     */
    async createScriptRaw(requestParameters: CreateScriptRequest): Promise<runtime.ApiResponse<Script>> {
        if (requestParameters.script === null || requestParameters.script === undefined) {
            throw new runtime.RequiredError('script','Required parameter requestParameters.script was null or undefined when calling createScript.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/scripts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScriptToJSON(requestParameters.script),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScriptFromJSON(jsonValue));
    }

    /**
     * Creates a new script  Method is allowed only for system administrators 
     * Create new script
     */
    async createScript(requestParameters: CreateScriptRequest): Promise<Script> {
        const response = await this.createScriptRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new script  Method is allowed only for system administrators 
     * Create new script
     */
    async createScriptWithHeaders(requestParameters: CreateScriptRequest): Promise<[ Script, Headers ]> {
        const response = await this.createScriptRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Deletes script Deletes an script Method is allowed only for system administrators
     */
    async deleteScriptRaw(requestParameters: DeleteScriptRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scriptId === null || requestParameters.scriptId === undefined) {
            throw new runtime.RequiredError('scriptId','Required parameter requestParameters.scriptId was null or undefined when calling deleteScript.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/scripts/{scriptId}`.replace(`{${"scriptId"}}`, encodeURIComponent(String(requestParameters.scriptId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes script Deletes an script Method is allowed only for system administrators
     */
    async deleteScript(requestParameters: DeleteScriptRequest): Promise<void> {
        await this.deleteScriptRaw(requestParameters);
    }

    /**
     * Deletes script Deletes an script Method is allowed only for system administrators
     */
    async deleteScriptWithHeaders(requestParameters: DeleteScriptRequest): Promise<Headers> {
        const response = await this.deleteScriptRaw(requestParameters);
        return response.raw.headers;
    }

    /**
     * Finds a script  Method is allowed only for system and metaform administrators 
     * Finds script
     */
    async findScriptRaw(requestParameters: FindScriptRequest): Promise<runtime.ApiResponse<Script>> {
        if (requestParameters.scriptId === null || requestParameters.scriptId === undefined) {
            throw new runtime.RequiredError('scriptId','Required parameter requestParameters.scriptId was null or undefined when calling findScript.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/scripts/{scriptId}`.replace(`{${"scriptId"}}`, encodeURIComponent(String(requestParameters.scriptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScriptFromJSON(jsonValue));
    }

    /**
     * Finds a script  Method is allowed only for system and metaform administrators 
     * Finds script
     */
    async findScript(requestParameters: FindScriptRequest): Promise<Script> {
        const response = await this.findScriptRaw(requestParameters);
        return await response.value();
    }

    /**
     * Finds a script  Method is allowed only for system and metaform administrators 
     * Finds script
     */
    async findScriptWithHeaders(requestParameters: FindScriptRequest): Promise<[ Script, Headers ]> {
        const response = await this.findScriptRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Lists scripts  Method is allowed only for system and metaform administrators 
     * Lists scripts
     */
    async listScriptsRaw(): Promise<runtime.ApiResponse<Array<Script>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/scripts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScriptFromJSON));
    }

    /**
     * Lists scripts  Method is allowed only for system and metaform administrators 
     * Lists scripts
     */
    async listScripts(): Promise<Array<Script>> {
        const response = await this.listScriptsRaw();
        return await response.value();
    }

    /**
     * Lists scripts  Method is allowed only for system and metaform administrators 
     * Lists scripts
     */
    async listScriptsWithHeaders(): Promise<[ Array<Script>, Headers ]> {
        const response = await this.listScriptsRaw();
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

    /**
     * Updates script Updates an script Method is allowed only for system administrators
     */
    async updateScriptRaw(requestParameters: UpdateScriptRequest): Promise<runtime.ApiResponse<Script>> {
        if (requestParameters.script === null || requestParameters.script === undefined) {
            throw new runtime.RequiredError('script','Required parameter requestParameters.script was null or undefined when calling updateScript.');
        }

        if (requestParameters.scriptId === null || requestParameters.scriptId === undefined) {
            throw new runtime.RequiredError('scriptId','Required parameter requestParameters.scriptId was null or undefined when calling updateScript.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/scripts/{scriptId}`.replace(`{${"scriptId"}}`, encodeURIComponent(String(requestParameters.scriptId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScriptToJSON(requestParameters.script),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScriptFromJSON(jsonValue));
    }

    /**
     * Updates script Updates an script Method is allowed only for system administrators
     */
    async updateScript(requestParameters: UpdateScriptRequest): Promise<Script> {
        const response = await this.updateScriptRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates script Updates an script Method is allowed only for system administrators
     */
    async updateScriptWithHeaders(requestParameters: UpdateScriptRequest): Promise<[ Script, Headers ]> {
        const response = await this.updateScriptRaw(requestParameters);
        const value = await response.value(); 
        return [ value, response.raw.headers ];
    }

}
