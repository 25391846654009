/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScriptType,
    ScriptTypeFromJSON,
    ScriptTypeFromJSONTyped,
    ScriptTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Script
 */
export interface Script {
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof Script
     */
    content: string;
    /**
     * 
     * @type {ScriptType}
     * @memberof Script
     */
    type: ScriptType;
}

export function ScriptFromJSON(json: any): Script {
    return ScriptFromJSONTyped(json, false);
}

export function ScriptFromJSONTyped(json: any, ignoreDiscriminator: boolean): Script {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'language': json['language'],
        'content': json['content'],
        'type': ScriptTypeFromJSON(json['type']),
    };
}

export function ScriptToJSON(value?: Script | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'language': value.language,
        'content': value.content,
        'type': ScriptTypeToJSON(value.type),
    };
}


